import { defineStore } from 'pinia'
import { PlaybackStore } from './playback_store.js'
import { ClickStore } from './click_store.js'
import { VideoStore } from './video_store.js'
import { highlightBox, focusAndHighlight } from '@/front/composables/UxComposer';

import dayjs from 'dayjs'


export const LeadCaptureStore = defineStore('leadCapture', {
  state: () => ({
    shown: false, // Track if lead capture has been shown to prevent multiple shows
    fields: [],
    config: {
      type: null,  // 'start', 'timestamp', 'first_click', 'specific_interaction'
      timestamp: null,
      interactionId: null
    }
  }),

  actions: {
    finishCapture() {
      const playbackStore = PlaybackStore()
      console.log("💟 LeadCaptureStore finishCapture", this.fields)

      let valid = true
      // Validate required fields
      this.fields.forEach(field => {
        if (valid && field.required && !playbackStore.variables[field.name.toUpperCase()]) {
          console.log("💟 LeadCaptureStore finishCapture required field not filled", field.label)
          field.type == 'checkbox' ? highlightBox('lead-capture-field-' + field.name, 10, 2000) : focusAndHighlight('lead-capture-field-' + field.name, 10, 2000,true)
          valid = false
        }

        // Format date, TOOD better way?
        if (field.type == 'date') {
          playbackStore.variables[field.name.toUpperCase()] = dayjs(playbackStore.variables[field.name.toUpperCase()]).format('MM/DD/YY')
        }
      })

      if (!valid) {
        return
      }

      this.shown = true
      const clickStore = ClickStore()
      clickStore.stopLeadCapture()
    },
    setConfig(video) {
      this.fields = []
      const playbackStore = PlaybackStore()
      // console.log("💟 LeadCaptureStore setConfig", video)

      if (video.captureName) { this.fields.push({label: 'name', name: 'name', type: 'text', format: 'text', variable: 'name', value: playbackStore.getVariable('name'), required: false})}
      if (video.captureEmail) { this.fields.push({label: 'email', name: 'email', type: 'text', format: 'email', variable: 'email', value: playbackStore.getVariable('email'), required: false})}
      if (video.capturePhone) { this.fields.push({label: 'phone', name: 'phone', type: 'text', format: 'phone', variable: 'phone', value: playbackStore.getVariable('phone'), required: false})}
      if (video.captureID) { this.fields.push({label: 'custom_id', name: 'custom_id', type: 'text', format: 'text', variable: 'custom_id', value: playbackStore.getVariable('custom_id'), required: false})}

      // TODO name vs label
      if (video.captureCustomFields) {
        video.captureCustomFields.forEach(field => {
          this.fields.push({label: field.label, name: field.name, type: field.type, format: field.format, variable: field.name, value: playbackStore.getVariable(field.name), required: false})
        })
      }

      this.fields.forEach(field => {
        if (video.requiredCaptureFields.includes(field.label)) {
          field.required = true
        }
        // console.log("💟 LeadCaptureStore:", field.label, field.type, field.required, field.value)
      })
      // Don't show if no fields
      if (this.fields.length == 0) {
        this.config.shown = true
        return
      }

      let needsCapture = 0
      this.fields.forEach(field => {
       if (!field.value) {
        needsCapture++
       }
      })
      if (needsCapture == 0) {
        this.config.shown = true
        return
      }

      // Set config based on video settings
      console.log("💟 LeadCaptureStore setConfig video.capture_auth_point", video.capture_auth_point)
      switch (video.capture_auth_point) {
        case -1:
          console.log("💟 LeadCaptureStore setConfig dont_capture")
          this.config.shown = true
          break
        case 0:
          console.log("💟 LeadCaptureStore setConfig start")
          this.config.type = 'start'
          break
        case 1:
          console.log("💟 LeadCaptureStore setConfig first_interaction")
          this.config.type = 'first_interaction'
          break
        case 2:
          console.log("💟 LeadCaptureStore setConfig timestamp", video.lead_capture_time)
          this.config.type = 'timestamp'
          this.config.timestamp = Number(video.capture_auth_value)  
          break
        case 3:
          console.log("💟 LeadCaptureStore setConfig interaction", video.capture_auth_value)
          this.config.type = 'specific_interaction'
          this.config.interactionId = video.capture_auth_value
          break
      }
      console.log("💟 LeadCaptureStore setConfig", this.config)
      // TODO check if field already met
      this.shown = false
    },

    checkTriggers(currentTime) {
      // TODO see if we already have all fields
      // console.log("💟 LeadCaptureStore checkTriggers", currentTime, this.config.type, this.config.timestamp)
      if (this.shown) return false// Don't show more than once
      
      const playbackStore = PlaybackStore()
      const videoStore = VideoStore()
      if (videoStore.video.manage) {
        return false
      }

      switch (this.config.type) {
        case 'start':
          if (currentTime < 0.5 && !playbackStore.leadCaptured) { // Show in first half second
            console.log("💟 LeadCaptureStore checkTriggers start", currentTime)
            this.showLeadCapture()
          } else {
            return false
          }
          break

        case 'timestamp':
          console.log("💟 LeadCaptureStore checkTriggers timestamp", currentTime, this.config.timestamp, currentTime >= this.config.timestamp)
          if (currentTime >= this.config.timestamp && !playbackStore.leadCaptured) {
            this.showLeadCapture()
          } else {
            return false
          }
          break
      }
      return false
    },

    handleInteractionClick(interactionId) {
      if (this.shown) return

      // TODO check if fields are already satisfied? shown?
      if (this.config.type === 'first_interaction' || 
        (this.config.type === 'specific_interaction' && 
         this.config.interactionId === interactionId)) {
        this.showLeadCapture()
      }
    },

    showLeadCapture() {
      const clickStore = ClickStore()
      this.shown = true
      clickStore.startLeadCapture() // Assume this exists to show the dialog
    }
  }
})

// // In your AnnStore, add to updateActive:
// updateActive(time) {
//   const leadCaptureStore = LeadCaptureStore()
//   leadCaptureStore.checkTriggers(time)
  
//   // Rest of your existing updateActive code...
// }

// // In your ClickComposer, add to clickInteraction:
// function clickInteraction(ann) {
//   const leadCaptureStore = LeadCaptureStore()
//   leadCaptureStore.handleInteractionClick(ann.id)
  
//   // Rest of your existing clickInteraction code...
// }