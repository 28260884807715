<script setup>
const props = defineProps({
  ann: {
    type: Object,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

import { VideoStore } from '@/front/stores/video_store.js';
import { PositionCalculator } from '@/front/composables/PositionCalculator.js';
import { AnnStore } from '@/front/stores/ann_store.js';
const annStore = AnnStore();

const { calculatePosition } = PositionCalculator()

const videoStore = VideoStore();
const baseDesign = videoStore.video.design || {}

const customStyle = props.ann.customStyle && JSON.parse(props.ann.customStyle) || {}
const styling = computed(() => {
  let opacity = props.disabled ? 0.5 : 1
  let cursor = props.disabled ? 'not-allowed' : 'pointer'
  
  const dimensions = calculatePosition(props.ann)
  return {
    'width': `${dimensions.width}px`,
    'height': `${dimensions.height}px`,
    'left': `${dimensions.left}px`,
    'top': `${dimensions.top}px`,
    'minHeight': `${dimensions.minHeight}px`,
    'fontSize': `${dimensions.fontSize}px`,
  }
})
const spotStyle = computed(() => {
  const ringPercentage = customStyle.ringWidth || 33
  const dimensions = calculatePosition(props.ann)
  const ringWidth = ringPercentage * 0.01 * dimensions.width
  const top = (100 - ringPercentage) / 2
  const left = (100 - ringPercentage) / 2
  let opacity = props.disabled ? 0.5 : 1
  let cursor = props.disabled ? 'not-allowed' : 'pointer'

  return {
    circle: {
      'background': props.ann.background_color || customStyle.background_color || baseDesign.button_background_color,
      'width': `${ringPercentage}%`,
      'height': `${ringWidth}px`,
      'position': 'absolute',
      'left': '50%',
      'top': '50%',
      'transform': `translate(-50%, -50%)`, // Centers the circle
      'opacity': opacity,
      'cursor': cursor,
    },
    ring: {
      'borderColor': props.ann.background_color || customStyle.background_color || baseDesign.button_background_color,
      'position': 'absolute',
      'left': '50%',
      'top': '50%',
      'height': `${ringWidth}px`,
      'width': `${ringWidth}px`,
      'borderWidth': `${ringWidth / 15}px`,
      'borderStyle': 'solid',
      'borderRadius': '50%', // Makes the ring a circle
      'opacity': opacity,
      'cursor': cursor,
    }
  }
})

console.log("Spot Style: ", spotStyle.value)
</script>

<template>

  <div :style="styling">
    <div class="spot-container relative h-full w-full"
      :class="annStore.isEditing ? 'animate-none' : `${ann.animation} hover:scale-105 z-10`">
      <span v-if="!disabled" class="spot-ring" :style="spotStyle.ring"></span>
      <span class="spot-circle" :style="spotStyle.circle"></span>
    </div>
    <!-- TOOD check size and show setting -->
    <div v-if="ann.extra" class="text-center text-2xl my-2">
      {{ ann.extra }}
    </div>
  </div>

</template>