import { defineStore } from 'pinia'
import { routerKey, useRoute } from 'vue-router';

import { DesignStore } from '@/front/stores/design_store.js';
import { AnnStore } from '@/front/stores/ann_store.js';
import { PlaybackStore } from '@/front/stores/playback_store.js';
import { PlayerStore } from '@/front/stores/player_store.js';
import { addToUrl } from '@/front/composables/StringComposer';

export const VideoStore = defineStore('videos', {
  state: () => {
    return {
      videos: [],
      loading: true,
      video: {},
      uploadProgress: 0,
      isUploading: false,
      uploadFile: null,
      uploadSource: {
        src: null,
        type: null,
        temporary: true,
      },
      uploadSettings: {
        title: '',
        transcode: true,
        transcribe: true
      },
      pagination: {page: 1},
      openaiCalls: []
    }
  },

  actions: {
    async index(path) {
      console.log("VideoStore index")
      this.loading = true
      return this.Api.get(path).then(response => {  
        this.pagination = response.data.pagination;
        this.videos = response.data.videos;        
      }).finally(() => {
        this.loading = false
      })
    },

    async deleteVideo() {
      console.log("🌜 VideoStore deleteVideo", this.video.id)
      return this.Api.destroy(`/videos/${this.video.id}`).then(response => {  
        this.video = {};
      })
    },  

    async checkPassword(password) {
      console.log("🌜 VideoStore checkPassword", password)
      return this.Api.get(`/videos/${this.video.id}/validate_password?mspw=${password}`).then(response => {  
        return response.data.valid
      })
    },

    async checkCode(email, code) {
      console.log("🌜 VideoStore checkCode", email, code)
      return this.Api.get(`/videos/${this.video.id}/validate_list?email=${email}&code=${code}`).then(response => {  
        console.log("🔊 VideoStore checkCode", response.data)
        return response.data
      })
    },

    async safeShow(id, args={}) {
      if (this.video && this.video.token == id) {
        console.log("✅ VideoStore safeShow video is up to date")
        return
      }
      console.log("💦 VideoStore safeShow calling show")
      return this.show(id, args)
    },

    async togglePublished(id) {
      this.video.published = !this.video.published
      return this.update()
    },

    async show(id, args={}) {
      console.log("🌜 VideoStore SHOW", id, args)
      const designStore = DesignStore();
      const annStore = AnnStore();
      this.loading = true
      this.video = {}
      let url = `/videos/${id}`
      if (!args.manage) {
        url += `/playback`
        if (args.plink_id) {
          url = addToUrl(url, `plink_id=${args.plink_id}`)
        } else if (args.password) {
          url = addToUrl(url, `mspw=${args.password}`)
        } else if (args.item_id) {
          url = addToUrl(url, `item_id=${args.item_id}`)
        } 
      }
      console.log("🌜 VideoStore SHOW url", url)
      return this.Api.get(url).then(response => {  
        console.log("🔊 VideoStore loaded", response.data.video)
        this.video = response.data.video;

        // For branching
        let url = '/videos/' + this.video.token
        let isEditOrPlayback = ['edit_video_path', 'playback_path'].includes(this.router.currentRoute.name)
        if (isEditOrPlayback) {
          if (!this.video.manage) {
            document.title = this.video.title;
            url = '/w/' + this.video.token
          } 
          console.log("🌜 VideoStore push URL", url)
          this.router.push(url)
        }

        if (this.video.design?.color) {
          console.log("🔊 VideoStore set color", this.video.design.color)
          document.documentElement.style.setProperty('--user-theme-color', this.video.design.color)
        }
        if (this.video.accessible.blocked) {
          console.log("🔊 VideoStore blocked", this.video.accessible.blocked)
          return
        }
        designStore.show(this.video.id)
        annStore.index(this.video.token)
      }).catch(error => {
        console.log("🔴 VideoStore show error", error)
      }).finally(() => {
        this.loading = false
        console.log("🌜 VideoStore SHOW finally done")
      })
    },

    async destroy(id) {      
      return this.Api.destroy(`/videos/${id}`).then(response => {  
        this.errors = {};
      }).catch(error => {
        this.errors = error.response.data.errors;
      }) 
    },

    async update() {
      this.saving = true
      console.log("Updating video", this.video)
      const videoWithoutTranscript = { ...this.video }
      delete videoWithoutTranscript.transcript
      return this.Api.put(`/videos/${this.video.id}`, videoWithoutTranscript).then(response => {  
        this.video = response.data.video;        
      }).finally(() => {
        this.saving = false
      })
    },

    async updateSource() {
      return this.Api.put(`/sources/${this.video.source.id}`, this.video.source).then(response => {  
        console.log("🔊 VideoStore updateSource", response.data)
      })
    },

    async createTranscript() {
      return this.Api.post(`/videos/${this.video.id}/transcribe`).then(response => {  
        console.log("🔊 VideoStore createTranscript", response.data)
      })
    },

    async updateTranscript() {
      return this.Api.put(`/transcripts/${this.video.transcript.id}`, { chunks: this.video.transcript.chunks }).then(response => {  
        console.log("🔊 VideoStore updateTranscript", response.data)
        this.show(this.video.token)
      })
    },

    async deleteTranscript() {
      return this.Api.destroy(`/transcripts/${this.video.transcript.id}`).then(response => {  
        console.log("🔊 VideoStore deleteTranscript", response.data)
      })
    },

    async duplicate(id, title, group_id, copy_interactions) {
      console.log("🌜 VideoStore duplicate", id, title, group_id, copy_interactions)
      return this.Api.post(`/videos/${id}/duplicate`, { title: title, group_id: group_id, copy_interactions: copy_interactions } ).then(response => {  
        return response.data.token
      })
    },

    async clearActivity() {
      return this.Api.post(`/videos/${this.video.id}/clear`).then(response => {  
        console.log("🔊 VideoStore clearActivity done", response.data)
      })
    },

    async getBranching() {
      return this.Api.get(`/videos/${this.video.id}/branches`).then(response => {  
        console.log("🔊 VideoStore getBranching done", response.data)
        return response.data
      })
    },

    async createChapter(time, title) {
      console.log("🌜 VideoStore createChapter", time, title)
      return this.Api.post(`/videos/${this.video.id}/chapters`, { chapter: { start: time, title: title } }).then(response => {  
        this.video.chapters.push(response.data.chapter);
        // Update chapters textrack url
      })
    },

    async deleteChapter(id) {
      return this.Api.destroy(`/chapters/${id}`).then(response => {  
        this.video.chapters = this.video.chapters.filter(chapter => chapter.id != id);
      })
    },

    async createSubtitle(language, url) {
      return this.Api.post(`/videos/${this.video.id}/subtitles`, { subtitle: { label: language, language: language, url: url, video_id: this.video.id } }).then(response => {  
        this.video.subtitles.push(response.data.subtitle);
      })
    },

    async deleteSubtitle(id) {
      return this.Api.destroy(`/subtitles/${id}`).then(response => {  
        this.video.subtitles = this.video.subtitles.filter(subtitle => subtitle.id != id);
      })
    },

    async getOpenaiCalls() {
      console.log("🌜 VideoStore getOpenaiCalls", this.video.id)
      return this.Api.get(`/videos/${this.video.id}/openai_calls`).then(response => {  
        console.log("🔊 VideoStore getOpenaiCalls", response.data)
        this.openaiCalls = response.data;
      })
    },

    async createFromKey(key) {
      console.log("🌜 VideoStore createFromKey", key, this.uploadFile.name)
      const args = { 
        key: key, 
        name: this.uploadFile.name, 
        size: this.uploadFile.size,
        title: this.uploadSettings.title,
        transcode: this.uploadSettings.transcode,
        transcribe: this.uploadSettings.transcribe
      }
      console.log("🌜 VideoStore createFromKey args", args)
      return this.Api.post('/videos/create_from_file', args).then(response => {  
        this.video = response.data.video; 
        console.log("UploadFile value", this.uploadFile)       
        this.uploadSource = {
          src: URL.createObjectURL(this.uploadFile),
          type: this.uploadFile.type,
          temporary: true
        }
        console.log("UploadSource", this.uploadSource)
      }).finally(() => {
        this.loading = false
      })
    },

    async getPurchaseLink(id) {
      const playbackStore = PlaybackStore();
      const playerStore = PlayerStore();

      return this.Api.get(`/videos/${id}/purchase?viewer_id=${playbackStore.viewer?.id}&t=${playerStore.fulltime}`).then(response => {  
        console.log("🔊 VideoStore getPurchaseLink", response.data)
      })
    }
  }
})