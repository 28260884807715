<script setup>
import { AnnStore } from "@/front/stores/ann_store.js";
const annStore = AnnStore();
import { PlayerStore } from "@/front/stores/player_store.js";
const playerStore = PlayerStore();
import { PlaybackStore } from "@/front/stores/playback_store.js";
const playbackStore = PlaybackStore();
import { ClipStore } from "@/front/stores/clip_store.js";
const clipStore = ClipStore();
import { ClickStore } from "@/front/stores/click_store.js";
const clickStore = ClickStore();
import { VideoStore } from "@/front/stores/video_store.js";
const videoStore = VideoStore();
import LayerButton from "@/front/components/annotations/LayerButton.vue";
import LayerImage from "./annotations/LayerImage.vue";
import LayerHotspot from "./annotations/LayerHotspot.vue";
import LayerText from "./annotations/LayerText.vue";
import LayerClip from "./annotations/LayerClip.vue";
import Button from "primevue/button";
import VueDraggableResizable from "vue-draggable-resizable";
import { clickInteraction, openMagicMenu, openGuide } from "@/front/composables/ClickComposer.js";

import { nextTick, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n({});

const props = defineProps({
  isMenu: {
    type: Boolean,
    default: false,
  },
  isEnd: {
    type: Boolean,
    default: false,
  },
});

const currentDimensions = ref({
  width: 0,
  height: 0,
  left: 0,
  top: 0,
  minHeight: 0,
});

const onDragStop = (x, y) => {
  annStore.editAnn.pos_left = pxToWidthPercent(x);
  annStore.editAnn.pos_top = pxToHeightPercent(y);
};

const onResizeCallback = (handle, x, y, width, height) => {
  currentDimensions.value = {
    width,
    height,
    left: x,
    top: y,
  };
  const fontRatio = pxToWidthPercent(width) / annStore.editAnn.pos_width;
  annStore.editAnn.font_size = Number(
    Math.round(annStore.editAnn.font_size * fontRatio).toFixed(1)
  );
  annStore.editAnn.pos_width = pxToWidthPercent(width);
  annStore.editAnn.pos_height = pxToHeightPercent(height);
};

const annList = computed(() => {
  if (props.isEnd) {
    return annStore.anns.filter((ann) => ann.tray_type == "ctaTray");
  } else if (props.isMenu) {
    return annStore.anns.filter((ann) => ann.tray_type == "resourceTray");
  }
  return annStore.anns.filter(
    (ann) =>
      !ann.tray_type &&
      annStore.active.some((activeAnn) => activeAnn.id === ann.id)
  );
});

const handleDoubleClick = (ann) => {
  if (playbackStore.manager) {
    clickNonEditingInteraction(ann, true);
  }
};

const isDisabled = (ann) => {
  return ann.post_click_state == "disabled" && ann.clicked
}

const isHidden = (ann) => {
  return ann.hidden || ann.post_click_state == "hidden" && ann.clicked
}

const handleSingleClick = (ann) => {
  console.log("🔎 IntLayer handleSingleClick", ann)
  if (isDisabled(ann)) {
    return
  }
  if (playbackStore.manager) {
    clickNonEditingInteraction(ann);
  } else {
    clickInteraction(ann);
  }
};

const clickNonEditingInteraction = (ann, forceEdit = false) => {
  if (annStore.isBulkEditing) {
    if (annStore.bulkEditInteractions.includes(ann.id)) {
      annStore.bulkEditInteractions = annStore.bulkEditInteractions.filter(
        (id) => id !== ann.id
      );
    } else {
      annStore.bulkEditInteractions.push(ann.id);
    }
    return;
  }
  if (!annStore.isEditing) {
    if (forceEdit) {
      annStore.startEdit(ann.id);
    } else {
      clickInteraction(ann);
    }
    return;
  }
  annStore.updateAnn(annStore.editAnn).then(() => {
    annStore.startEdit(ann.id);
  });
};

const handleLayerClick = () => {
  if (props.isMenu || annStore.isBulkEditing || clipStore.src) {
    return;
  } else {
    playerStore.togglePlayer();
  }
};

const animations = {
  fadeLeft: { in: "animate__fadeInLeft", out: "animate__fadeOutLeft" },
  fadeRight: { in: "animate__fadeInRight", out: "animate__fadeOutRight" },
  rotate: { in: "animate__rotateIn", out: "animate__rotateOut" },
  zoom: { in: "animate__zoomIn", out: "animate__zoomOut" },
  fadeUp: { in: "animate__fadeInUp", out: "animate__fadeOutUp" },
  fadeDown: { in: "animate__fadeInDown", out: "animate__fadeOutDown" },
  zoomDown: { in: "animate__zoomInDown", out: "animate__zoomOutDown" },
  zoomLeft: { in: "animate__zoomInLeft", out: "animate__zoomOutLeft" },
  zoomRight: { in: "animate__zoomInRight", out: "animate__zoomOutRight" },
  zoomUp: { in: "animate__zoomInUp", out: "animate__zoomOutUp" },
  bounce: { in: "animate__bounceIn", out: "animate__bounceOut" },
  fade: { in: "animate__fadeIn", out: "animate__fadeOut" },
  bounceLeft: { in: "animate__bounceInLeft", out: "animate__bounceOutLeft" },
  bounceRight: { in: "animate__bounceInRight", out: "animate__bounceOutRight" },
  bounceUp: { in: "animate__bounceInUp", out: "animate__bounceOutUp" },
  bounceDown: { in: "animate__bounceInDown", out: "animate__bounceOutDown" },
  slideLeft: { in: "animate__slideInLeft", out: "animate__slideOutLeft" },
  slideRight: { in: "animate__slideInRight", out: "animate__slideOutRight" },
  slideUp: { in: "animate__slideInUp", out: "animate__slideOutUp" },
  slideDown: { in: "animate__slideInDown", out: "animate__slideOutDown" },
};

const getAnimationClass = (transition, isLeaving = false) => {
  const animation = animations[transition] || animations.fade;
  return isLeaving ? "animate__fadeOut" : animation.in;
};

const handleLeave = (el) => {
  // Get the current animation class
  const currentClass = Array.from(el.classList).find((cls) =>
    cls.startsWith("animate__")
  );
  if (!currentClass) return;

  // Find the transition type from the class
  const transitionType = Object.entries(animations).find(
    ([_, classes]) => classes.in === currentClass
  )?.[0];

  if (transitionType) {
    // Apply the corresponding leave animation
    el.classList.remove(currentClass);
    el.classList.add(getAnimationClass(transitionType, true));
  }
};

import { PositionCalculator } from "@/front/composables/PositionCalculator.js";
import { SearchList01Icon, MenuSquareIcon } from "hugeicons-vue";
import { localize } from "../composables/StringComposer";
const { calculatePosition, pxToWidthPercent, pxToHeightPercent } =
  PositionCalculator();

const getClass = (ann) => {
  return {
    "bulk-selected":
      annStore.isBulkEditing && annStore.bulkEditInteractions.includes(ann.id),
    "bulk-not-selected":
      annStore.isBulkEditing && !annStore.bulkEditInteractions.includes(ann.id),
  };
};

const onClipEnded = () => {
  clipStore.clear();
  playerStore.play("IntLayer onClipEnded");
};

const isImage = computed(() => annStore.editAnn?.type_of === "image");
const isClip = computed(() => annStore.editAnn?.type_of === "video");

const aspectRatio = computed(() => {
  if (isImage.value) return annStore.editAnn.aspect_ratio || null;
  if (isClip.value) return clipStore.aspectRatio || 1.77;
  return null;
});

const dimensions = computed(() => {
  if (!annStore.editAnn) return { width: 0, height: 0, left: 0, top: 0 };
  if (currentDimensions.value.width > 0) {
    return currentDimensions.value;
  }
  const pos = calculatePosition(annStore.editAnn);
  if ((isImage.value || isClip.value) && aspectRatio.value) {
    const width = pos.width;
    const height = width / aspectRatio.value;
    const newDimensions = { ...pos, width, height };
    currentDimensions.value = newDimensions;
    return newDimensions;
  }
  return pos;
});


watch(
  () => annStore.editAnn,
  () => {
    currentDimensions.value = {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      minHeight: 0,
    };
  },
  { deep: true }
);

const visibleAnns = computed(() =>
  annList.value.filter(
    (ann) =>
      !ann.hidden && !(annStore.isEditing && annStore.editAnn.id === ann.id)
  )
);

const magicMenuIconVisible = computed(() => {
  return playerStore.playerState.controlsVisible && !props.isMenu && !props.isEnd && videoStore.video.design.magic_menu?.visible && annStore.anns.filter(ann => ann.tray_type == "resourceTray").length > 0;
});

const guideIconVisible = computed(() => {
  let chapters = videoStore.video.chapters.length > 0 && videoStore.video.design.guide_chapters;
  let interactions = annStore.anns.length > 0 && videoStore.video.design.guide_interactions;
  let transcripts = videoStore.video.transcript && videoStore.video.design.guide_transcript;
  return playerStore.playerState.controlsVisible && !props.isMenu && !props.isEnd && (chapters || interactions || transcripts);
});

const showPinnedChapters = computed(() => {
  return videoStore.video.chapters.length > 0 && videoStore.video.design.static_chapters;
});

const clickChapter = (chapter) => {
  console.log(chapter);
  playerStore.seek(chapter.start);
};

</script>

<template>
  <div
    id="int-layer"
    class="absolute overflow-y-scroll"
    :style="playerStore.layerStyle"
    @click.stop="handleLayerClick"
  >
    <TransitionGroup
      tag="div"
      class="ann-container"
      :enter-active-class="`animate__animated`"
      :leave-active-class="`animate__animated`"
      :move-class="`transition-move`"
      @leave="handleLeave"
    >
      <div
        v-for="ann in visibleAnns"
        :key="ann.id"
        :class="[
          'interaction-content',
          getAnimationClass(ann.transition),
          getClass(ann),
        ]"
        @click.stop="handleSingleClick(ann)"
        @dblclick.stop="handleDoubleClick(ann)"
      >
        <LayerButton
          v-if="ann.type_of == 'button' && !isHidden(ann)"
          :ann="ann"
          :disabled="isDisabled(ann)"
          class="absolute"
        />
        <LayerImage
          v-else-if="ann.type_of == 'image' && !isHidden(ann)"
          :ann="ann"
          :disabled="isDisabled(ann)"
          class="absolute"
        />
        <LayerHotspot
          v-else-if="ann.type_of == 'hotspot' && !isHidden(ann)"
          :ann="ann"
          :disabled="isDisabled(ann)"
          class="absolute"
        />
        <LayerText
          v-else-if="ann.type_of == 'text' && !isHidden(ann)"
          :ann="ann"
          :disabled="isDisabled(ann)"
          class="absolute"
        />
      </div>
    </TransitionGroup>

    <TransitionGroup name="fade" tag="button">
      <!-- Magic Menu -->
      <Button
        v-if="magicMenuIconVisible"
        v-tooltip.right="localize('show_magic_menu')"
        class="!absolute left-0 top-[calc(50%-2vw)] h-[4vw] rounded-none rounded-tr-xl rounded-br-xl !bg-[var(--user-theme-color)] border-2 border-white border-l-0"
        @click.stop="openMagicMenu"
      >
        <MenuSquareIcon class="w-full h-full p-0.5 max-h-[2vw] max-w-[2vw]" />
      </Button>

      <!-- Guide -->
      <Button
        v-if="guideIconVisible"
        v-tooltip.left="localize('show_guide')"
        class="!absolute right-0 top-[calc(50%-2vw)] h-[4vw] rounded-none rounded-tl-xl rounded-bl-xl !bg-[var(--user-theme-color)] border-2 border-white border-r-0"
        @click.stop="openGuide"
      >
        <SearchList01Icon class="w-full h-full p-0.5 max-h-[2vw] max-w-[2vw]" />
      </Button>

      <div v-if="showPinnedChapters" class="absolute top-2 left-2">
        <div v-for="chapter in videoStore.video.chapters" :key="chapter.id" class="text-white mb-2 p-0 text-lg bg-black/80 rounded-md px-2 py-0" @click.stop="clickChapter(chapter)">
          {{ chapter.title }}
        </div>
      </div>
    </TransitionGroup>

    <vue-draggable-resizable
      v-if="
        annStore.isEditing &&
        annStore.editAnn &&
        annStore.needsPlacement &&
        !annStore.imageLoading
      "
      :w="dimensions.width"
      :h="dimensions.height"
      :x="dimensions.left"
      :y="dimensions.top"
      :lock-aspect-ratio="isImage || isClip"
      :aspect-ratio="aspectRatio"
      :active="true"
      :prevent-deactivation="true"
      :handles="['tr', 'tl', 'br', 'bl', 'ml', 'mr']"
      :parent="true"
      @dragStop="onDragStop"
      :onResize="onResizeCallback"
    >
      <LayerButton
        v-if="annStore.editAnn.type_of === 'button'"
        :ann="annStore.editAnn"
        :editing="true"
      />
      <LayerImage
        v-else-if="annStore.editAnn.type_of == 'image'"
        :ann="annStore.editAnn"
        :editing="true"
      />
      <LayerHotspot
        v-else-if="annStore.editAnn.type_of == 'hotspot'"
        :ann="annStore.editAnn"
        :editing="true"
      />
      <LayerText
        v-else-if="annStore.editAnn.type_of == 'text'"
        :ann="annStore.editAnn"
        :editing="true"
      />
      <LayerClip
        v-else-if="annStore.editAnn.type_of == 'video'"
        :ann="annStore.editAnn"
        :editing="true"
      />
    </vue-draggable-resizable>
  </div>
</template>

<style>
@import "vue-draggable-resizable/style.css";

.interaction-content {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  transform-origin: center center;
}

.transition-move {
  transition: transform 0.5s ease;
}

.bulk-not-selected {
  opacity: 0.5;
  filter: brightness(40%);
}
</style>