<script setup>
import { AnnStore } from '@/front/stores/ann_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
import { PlayerStore } from '@/front/stores/player_store.js'
import { ClickStore } from '@/front/stores/click_store.js'
const clickStore = ClickStore()
import { DesignStore } from '@/front/stores/design_store.js'
import { finishMessage } from '@/front/composables/ClickComposer.js'
import { PositionCalculator } from '@/front/composables/PositionCalculator.js'
const { getRelativeLayerDimensions } = PositionCalculator()
const playerStore = PlayerStore()
const videoStore = VideoStore()

import { PlaybackStore } from '@/front/stores/playback_store.js'
const playbackStore = PlaybackStore()
import Button from 'primevue/button';
import { PlayIcon } from 'hugeicons-vue'
import LoopingPlayer from '@/front/components/LoopingPlayer.vue'
const { t } = useI18n({});
// Have to calcualte aspect ratio to pin layer to actual video
// TODO handle cover mode

const getIntroLayerStyle = computed(() => {
  console.log("getIntroLayerStyle", videoStore.video.design.use_moving_preview)
  let styles = {}
  if (!videoStore.video.id) {
    return styles
  }
  if (videoStore.video.design.thumbnail) {
    styles = {
      // backgroundImage: `url(${videoStore.video.design.thumbnail})`,
      // backgroundColor: 'black',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }
  }
  return styles
})


const startVideo = () => {
  console.log("startVideo")

  if (playbackStore.existingPlay) {
    console.log("startVideo existingPlay", playbackStore.existingPlay)
    clickStore.showResumeDialog()
  } else {
    console.log("startVideo newPlay")
    playerStore.togglePlayer()
  }
}

</script>

<template>
  <div v-if="videoStore.video.id" id="intro-layer" @click.stop="startVideo" class="absolute top-0 left-0 w-full h-full fill-height" :style="getIntroLayerStyle"> 
    <div class="flex flex-col items-center justify-center h-full cursor-pointer">
      <img v-if="videoStore.video.design.play_button_image && playerStore.ready" :src="videoStore.video.design.play_button_image" class="w-1/5 min-w-24 z-10 pulse_v1">
      <PlayIcon v-else class="text-white w-24 h-24 z-10 pulse_v1" />
      <LoopingPlayer v-if="videoStore.video.design.use_moving_preview" class="absolute top-0 left-0 w-full h-full z-0 bg-black" />

    </div>
  </div>
</template>

<style>
</style>
