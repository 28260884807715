<script setup>
import { AnnStore } from '@/front/stores/ann_store.js'
import { PlayerStore } from '@/front/stores/player_store.js'
import { ClickStore } from '@/front/stores/click_store.js'
import { DesignStore } from '@/front/stores/design_store.js'
import { PlaybackStore } from '@/front/stores/playback_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
const videoStore = VideoStore()
import { clickInteraction, doAction } from '@/front/composables/ClickComposer.js'
import { PositionCalculator } from '@/front/composables/PositionCalculator.js'
const { getRelativeLayerDimensions } = PositionCalculator()
const playerStore = PlayerStore()
const clickStore = ClickStore()
const annStore = AnnStore()
const designStore = DesignStore()
const playbackStore = PlaybackStore()
import { equalsIgnoreCase } from '@/front/composables/StringComposer';
const DatePicker = defineAsyncComponent(() => import('primevue/datepicker'));
const Select = defineAsyncComponent(() => import('primevue/select'));
const InputNumber = defineAsyncComponent(() => import('primevue/inputnumber'));
const Checkbox = defineAsyncComponent(() => import('primevue/checkbox'));
const QuestionCorrectness = defineAsyncComponent(() => import('@/front/components/QuestionCorrectness.vue'));

import Button from 'primevue/button';
import TextArea from 'primevue/textarea';
import { ArrowRight02Icon, ArrowLeft02Icon, StarIcon, ViewIcon } from 'hugeicons-vue'
import { compile } from 'vue'
const { t } = useI18n({});
const VideoRecorder = defineAsyncComponent(() => import('@/front/components/VideoRecorder.vue'))
const AudioRecorder = defineAsyncComponent(() => import('@/front/components/AudioRecorder.vue'))
const ImageResponse = defineAsyncComponent(() => import('@/front/components/ImageResponse.vue'))
const DrawCreationLayer = defineAsyncComponent(() => import('@/front/components/DrawCreationLayer.vue'))
const BarChart = defineAsyncComponent(() => import('@/front/components/BarChart.vue'))
import { localize } from '@/front/composables/StringComposer'

const props = defineProps({
  readonly: {
    type: Boolean,
    default: false
  }
})
// Have to calcualte aspect ratio to pin layer to actual video
// TODO handle cover mode

const getStyles = computed(() => {
  let styles = {}

  const useEditValues = annStore.editAnn && annStore.editAnn.question_use_custom_design
  const useActiveValues = !useEditValues && clickStore.questionActive && clickStore.questionActive.question_use_custom_design
  const left = useEditValues ? annStore.editAnn.pos_left : useActiveValues ? clickStore.questionActive.pos_left : designStore.design.question_left
  const top = useEditValues ? annStore.editAnn.pos_top : useActiveValues ? clickStore.questionActive.pos_top : designStore.design.question_top
  const width = useEditValues ? annStore.editAnn.pos_width : useActiveValues ? clickStore.questionActive.pos_width : designStore.design.question_width
  const height = useEditValues ? annStore.editAnn.pos_height : useActiveValues ? clickStore.questionActive.pos_height : designStore.design.question_height
  const backgroundColor = useEditValues ? annStore.editAnn.background_color : useActiveValues ? clickStore.questionActive.background_color : designStore.design.question_background_color
  const textColor = useEditValues ? annStore.editAnn.text_color : useActiveValues ? clickStore.questionActive.text_color : designStore.design.question_text_color
  const correctColor = useEditValues ? annStore.editAnn.question_correct_color : useActiveValues ? clickStore.questionActive.question_correct_color : designStore.design.correct_color
  const incorrectColor = useEditValues ? annStore.editAnn.question_incorrect_color : useActiveValues ? clickStore.questionActive.question_incorrect_color : designStore.design.incorrect_color
  const answersColor = useEditValues ? annStore.editAnn.answers_color : useActiveValues ? clickStore.questionActive.answers_color : designStore.design.question_answers_color

  styles = getRelativeLayerDimensions(top, left, width, height)
  styles.backgroundColor = backgroundColor
  styles.color = textColor
  return {
    general: styles,
    prompt: {
      color: useEditValues ? annStore.editAnn.text_color : useActiveValues ? clickStore.questionActive.text_color : designStore.design.prompt_color
    },
    correct_answer: {
      color: correctColor
    },
    incorrect_answer: {
      color: incorrectColor
    },
    answers: {
      color: answersColor
    }
  }
})

let correctState = reactive({
  visible: false,
  set: false,
  isCorrect: false,
  correctValue: '',
  answerValue: '',
  action: '',
  value: ''
})

const answerChoices = computed(() => {
  return annStore.editAnn ? annStore.editAnn.question_answers : clickStore.questionActive.question_answers
})

const handleDrawingSuccess = (image) => {
  console.log("💟 QuestionLayer handleDrawingSuccess", image)
  response.value = image
  submitDrawing()
}


const handleRating = (value) => {
  console.log("💟 QuestionLayer handleRating", value)
  response.value = value
  submitResponse()
}

const handleLikert = (value) => {
  console.log("💟 QuestionLayer handleLikert", value)
  response.value = value
  submitResponse()
}

const submitDrawing = () => {
  console.log('questionLayer submitDrawing', response.value)
  if (annStore.isEditing) {
    console.log("💟 QuestionLayer submitDrawing annStore.isEditing, return")
    return
  }
  playbackStore.safeTrackDrawingResponse(videoStore.video, clickStore.questionActive, response.value).then(() => {
    response.value = null
    answeringDrawing.value = false
    clickStore.stopDrawing()
    tryContinueQuestion()
  })
}

const submitResponse = () => {
  console.log('questionLayer submitResponse', response.value)
  if (annStore.isEditing) {
    console.log("💟 QuestionLayer submitResponse annStore.isEditing, return")
    return
  }
  // TODO make this async?
  if (clickStore.questionActive.question_type == 'all') {
    response.value = response.value.sorlocalize().join(', ')
  }
  switch (clickStore.questionActive.question_type) {
    case 'free':
      handleMultiCorrectness(response.value)
      break
    case 'number':
      handleMultiCorrectness(response.value)
      break
    case 'date':
      response.value = dmyyyy(response.value)
      handleMultiCorrectness(response.value)
      break
    case 'all':
      handleAllCorrectness(response.value)
      break
    case 'select':
      handleSingleCorrectness(response.value)
      break
    case 'poll':
      handlePoll(response.value)
      break
  }
  playbackStore.safeTrackResponse(videoStore.video, clickStore.questionActive, response.value.toString(), clickStore.questionCorrectness)
  handleVariable(response.value)
  let shouldDoAction = clickStore.questionCorrectness.action && clickStore.questionCorrectness.value && clickStore.questionCorrectness.action != 'message'
    if (shouldDoAction) {
      console.log("💟 QuestionLayer submitResponse action", clickStore.questionCorrectness.action, clickStore.questionCorrectness.value)
      doAction({
        click_action: clickStore.questionCorrectness.action,
        click_value: clickStore.questionCorrectness.value
      })
    } else {
      console.log("💟 QuestionLayer submitResponse no action")
  }
  response.value = null
  // TODO this was causing a skip
  // tryContinueQuestion()
}

const prompt = computed(() => {
  return clickStore.questionActive.question_prompt
})

// TODO localize these?
const likertOptions = [
  { label: localize('strongly_disagree'), value: "Strongly Disagree" },
  { label: localize('disagree'), value: "Disagree" },
  { label: localize('neutral'), value: "Neutral" },
  { label: localize('agree'), value: "Agree" },
  { label: localize('strongly_agree'), value: "Strongly Agree" }
]

const showSubmitButton = computed(() => {
  return ['free', 'all', 'select', 'number', 'date'].includes(clickStore.questionActive.question_type)
})

const canSubmit = computed(() => {
  return response.value != null
})

const answeringVideo = ref(false)
const answeringAudio = ref(false)
const answeringDrawing = ref(false)

const startAnswerVideo = () => {
  console.log("💟 QuestionLayer startAnswerVideo")
  answeringVideo.value = true
}

const startAnswerAudio = () => {
  console.log("💟 QuestionLayer startAnswerAudio")
  answeringAudio.value = true
}

const startAnswerDrawing = () => {
  console.log("💟 QuestionLayer startAnswerDrawing")
  answeringDrawing.value = true
  clickStore.startDrawing()
}

const handleMultiCorrectness = (answer) => {
  clickStore.resetQuestionCorrectness()
  const correctAnswers = clickStore.questionActive.question_multi_correct
  if (!correctAnswers || correctAnswers.length == 0) {
    clickStore.questionCorrectness.visible = false
    return
  }
  console.log("💟 QuestionLayer handleMultiCorrectness correctAnswers / answer", correctAnswers, answer)

  const isCorrect = !!correctAnswers.find(correctAnswer => equalsIgnoreCase(answer, correctAnswer))
  console.log("💟 QuestionLayer handleMultiCorrectness isCorrect", isCorrect)

  clickStore.questionCorrectness.correctValue = correctAnswers.join(', ')
  clickStore.questionCorrectness.isCorrect = isCorrect
  clickStore.questionCorrectness.answerValue = answer
  clickStore.questionCorrectness.force = clickStore.isForceCorrect
  clickStore.questionCorrectness.visible = designStore.design.question_correctness
  clickStore.questionCorrectness.action = isCorrect ? clickStore.questionActive.question_correct_action : clickStore.questionActive.question_incorrect_action
  clickStore.questionCorrectness.value = isCorrect ? clickStore.questionActive.question_correct_value : clickStore.questionActive.question_incorrect_value
  clickStore.questionCorrectness.set = true

  console.log("💟 QuestionLayer handleMultiCorrectness clickStore.questionCorrectness isCorrect", isCorrect)
  console.log("💟 QuestionLayer handleMultiCorrectness clickStore.questionCorrectness visible", clickStore.questionCorrectness.visible)
  console.log("💟 QuestionLayer handleMultiCorrectness clickStore.questionCorrectness action", clickStore.questionCorrectness.action)
  console.log("💟 QuestionLayer handleMultiCorrectness clickStore.questionCorrectness value", clickStore.questionCorrectness.value)
}

const handleAllCorrectness = (answer) => {
  clickStore.resetQuestionCorrectness()
  const correctAnswers = clickStore.questionActive.question_multi_correct
  if (!correctAnswers || correctAnswers.length == 0) {
    clickStore.questionCorrectness.visible = false
    return
  }
  console.log("💟 QuestionLayer handleAllCorrectness correctAnswers / answer", correctAnswers, answer)

  const isCorrect = correctAnswers.every(correctAnswer => answer.includes(correctAnswer))
  console.log("💟 QuestionLayer handleAllCorrectness isCorrect", isCorrect)

  clickStore.questionCorrectness.correctValue = correctAnswers.join(', ')
  clickStore.questionCorrectness.isCorrect = isCorrect
  clickStore.questionCorrectness.answerValue = answer.join(', ')
  clickStore.questionCorrectness.force = clickStore.isForceCorrect
  clickStore.questionCorrectness.visible = designStore.design.question_correctness
  clickStore.questionCorrectness.action = isCorrect ? clickStore.questionActive.question_correct_action : clickStore.questionActive.question_incorrect_action
  clickStore.questionCorrectness.value = isCorrect ? clickStore.questionActive.question_correct_value : clickStore.questionActive.question_incorrect_value
  clickStore.questionCorrectness.set = true

  console.log("💟 QuestionLayer handleAllCorrectness clickStore.questionCorrectness isCorrect", isCorrect)
  console.log("💟 QuestionLayer handleAllCorrectness clickStore.questionCorrectness visible", clickStore.questionCorrectness.visible)
  console.log("💟 QuestionLayer handleAllCorrectness clickStore.questionCorrectness action", clickStore.questionCorrectness.action)
  console.log("💟 QuestionLayer handleMultiCorrectness clickStore.questionCorrectness value", clickStore.questionCorrectness.value)
}

const handlePoll = (chosenAnswer) => {
  console.log("💟 QuestionLayer handlePoll", chosenAnswer)
  clickStore.questionActive.question_answers.forEach(answer => {
    if (answer.text == chosenAnswer.text) {
      answer.count = (answer.count || 0) + 1
    }
  })
  clickStore.pollActive = true
}

// Used for single buttons for multiple choice
const handleSingleCorrectness = (answer) => {
  clickStore.resetQuestionCorrectness()
  console.log("💟 QuestionLayer handleSingleCorrectness", answer)
  let correctAnswerValue = clickStore.questionActive.question_single_correct
  // Check correctness 
  if (correctAnswerValue) {
    console.log("💟 QuestionLayer handleSingleCorrectness correct_answer_value", correctAnswerValue)
    // Set values for display
    clickStore.questionCorrectness.correctValue = correctAnswerValue
    clickStore.questionCorrectness.answerValue = answer.text
    clickStore.questionCorrectness.action = answer.click_action
    clickStore.questionCorrectness.value = answer.click_value
    clickStore.questionCorrectness.force = clickStore.isForceCorrect

    if (isMultiple || isSelect) {
      console.log("💟 QuestionLayer handleAnswerClick correct")
      clickStore.questionCorrectness.isCorrect = equalsIgnoreCase(answer.text, correctAnswerValue)
    } else {
      console.log("💟 QuestionLayer handleAnswerClick incorrect")
    }
    clickStore.questionCorrectness.visible = designStore.design.question_correctness
    clickStore.questionCorrectness.set = true
  }
  console.log("💟 QuestionLayer handleSingleCorrectness clickStore.questionCorrectness", clickStore.questionCorrectness)
}

const handleAnswerClick = (answer) => {
  console.log("💟 QuestionLayer handleAnswerClick", answer)

  const id = clickStore.questionActive?.id
  handleSingleCorrectness(answer)
  handleVariable(answer.text)
  console.log("💟 QuestionLayer handleAnswerClick clickStore.questionActive?.question_type", clickStore.questionActive?.question_type)
  if (clickStore.questionActive?.question_type == 'poll') {
    handlePoll(answer)
  }
  const shouldPerformAction = !clickStore.questionCorrectness.visible || clickStore.questionCorrectness.action != 'message'
  response.value = answer.text
  submitResponse()
  // Click interaction
  // clickInteraction({
  //   id: clickStore.questionActive.id,
  //   click_action: answer.click_action,
  //   click_value: answer.click_value
  // }, doAction)
  if (shouldPerformAction && id) {
    doAction({
      id: id,
      click_action: answer.click_action,
      click_value: answer.click_value
    })
  }


  if (answer.click_action == 'audio') {
    console.log("💟 QuestionLayer handleAnswerClick audio, dont go to next question")
  } else {
    tryContinueQuestion()
  }
}

const isMultiple = computed(() => {
  return clickStore.questionActive.question_type == 'multiple'
})

const isSelect = computed(() => {
  return clickStore.questionActive.question_type == 'select'
})

const isAll = computed(() => {
  return clickStore.questionActive.question_type == 'all'
})

const isFree = computed(() => {
  return clickStore.questionActive.question_type == 'free'
})

const isDate = computed(() => {
  return clickStore.questionActive.question_type == 'date'
})

const isNumber = computed(() => {
  return clickStore.questionActive.question_type == 'number'
})

const tryContinueQuestion = () => {
  if (!clickStore.questionCorrectness.visible && !clickStore.questionCorrectness.force && !clickStore.pollActive) {
    console.log("💟 QuestionLayer tryContinueQuestion, finishCurrentQuestion")
    annStore.finishCurrentQuestion()
  }
}

const handleVariable = (value) => {
  console.log("💟 QuestionLayer handleVariable", clickStore.questionActive.variable, value)
  if (clickStore.questionActive.variable) {
    console.log("💟 QuestionLayer handleVariable variable", clickStore.questionActive.variable, value)
    playbackStore.setVariable(clickStore.questionActive.variable, value)
  }
}

const response = ref(null)

// Dont allow for more clicking when playing video or audio clips
const disabledResponses = computed(() => {
  return clickStore.clipActive || clickStore.audioActive
})

const finishCorrectness = () => {
  console.log("💟 QuestionLayer finishCorrectness", clickStore.questionCorrectness.isCorrect)
  if (clickStore.isForceCorrect && !clickStore.questionCorrectness.isCorrect) {
    console.log("💟 QuestionLayer finishCorrectness force")
    clickStore.resetQuestionCorrectness()
  } else {
    console.log("💟 QuestionLayer finishCorrectness finishCurrentQuestion")
    annStore.finishCurrentQuestion()
  }
}

const handleVideoSuccess = (uploadUrl) => {
  console.log("💟 QuestionLayer handleVideoSuccess", uploadUrl)
  playbackStore.safeTrackVideoResponse(videoStore.video, clickStore.questionActive, uploadUrl).then(() => {
    annStore.finishCurrentQuestion()
    answeringVideo.value = false

  })
}

const handleAudioSuccess = (uploadUrl) => {
  console.log("💟 QuestionLayer handleAudioSuccess", uploadUrl)
  playbackStore.safeTrackAudioResponse(videoStore.video, clickStore.questionActive, uploadUrl).then(() => {
    annStore.finishCurrentQuestion()
    answeringAudio.value = false
  })
}

const handleImageSuccess = (uploadUrl) => {
  console.log("💟 QuestionLayer handleImageSuccess", uploadUrl)
  playbackStore.safeTrackImageResponse(videoStore.video, clickStore.questionActive, uploadUrl).then(() => {
    annStore.finishCurrentQuestion()
  })
}

const editManage = computed(() => {
  return annStore.isEditing || videoStore.video.manage
})

const skipQuestion = () => {
  console.log("💟 QuestionLayer skipQuestion")
  clickStore.clearTimedPause()
  clickStore.pollActive = false
  annStore.finishCurrentQuestion()
}

const rewindQuestion = () => {
  let rewindTime = clickStore.questionActive.question_rewind_time 
  if (rewindTime) {
    clickStore.resetAll()
    console.log("💟 QuestionLayer rewindQuestion to " + rewindTime)
    playerStore.seekAndPlay(rewindTime).then(() => {
      console.log("💟 QuestionLayer rewindQuestion seekAndPlay", rewindTime)
    })
  } else {
    console.log("💟 QuestionLayer rewindQuestion no rewind time")
  }
}

const answeringBlockingQuestion = computed(() => {
  return (answeringVideo.value || answeringAudio.value || answeringDrawing.value)
})

const showSkipButton = computed(() => {
  return !answeringBlockingQuestion.value && (clickStore.questionActive.question_skippable || editManage)
})

const showRewindButton = computed(() => {
  return !answeringBlockingQuestion.value && clickStore.questionActive.question_rewindable
})

const finishPoll = () => {
  clickStore.pollActive = false
  annStore.finishCurrentQuestion()
}

const pollHeight = computed(() => {
  return playerStore.layerDimensions.height - 50
})







</script>

<template>
  <div id="question-layer"
    class="absolute top-0 left-0 w-full h-full overflow-y-scroll flex flex-col justify-center items-center"
    :style="getStyles.general">
    <div class="flex flex-col items-center w-full">
      <div class="w-full max-w-[90%] mx-auto">

        <div v-if="answeringVideo" class="w-full max-w-sm mx-auto">
          <Suspense>
            <VideoRecorder @close="answeringVideo = false" @success="handleVideoSuccess" />
          </Suspense>
        </div>
        <div v-else-if="answeringAudio" class="w-full max-w-sm mx-auto">
          <Suspense>
            <AudioRecorder @close="answeringAudio = false" @success="handleAudioSuccess" />
          </Suspense>
        </div>
        <div v-else-if="answeringDrawing" class="w-full max-w-sm mx-auto">
          <Suspense>
            <DrawCreationLayer @save="handleDrawingSuccess" />
          </Suspense>
        </div>

        <!-- Displaying poll -->
        <div v-else-if="clickStore.pollActive" class="w-full bg-white h-full absolute top-0 left-0 text-black">
          <Suspense>
            <BarChart type="poll" class="w-full h-full" :height="pollHeight" width="100%" :download="false"/>
          </Suspense>
          <div class="w-full text-center absolute bottom-4 left-0">
            <Button @click.stop="finishPoll" size="small"> {{ localize('continue') }} </Button>
          </div>
        </div>

        <!-- Displaying correctnesss -->
        <div v-else-if="clickStore.questionCorrectness.visible">
          <QuestionCorrectness :styles="getStyles" />
          <div class="mt-2 w-full text-center">
            <Button @click.stop="finishCorrectness"> {{ clickStore.isForceCorrect &&
              !clickStore.questionCorrectness.isCorrect ? localize('try_again') : localize('continue') }} </Button>
          </div>
        </div>

        <!-- Displaying choices -->
        <div v-else>
          <div class="text-center -mt-8 mb-2">
            <h1 class="text-3xl font-bold tracking-wide" :style="getStyles.prompt"> {{ prompt ||
              localize('enter_prompt') }} </h1>
          </div>
          <div v-if="clickStore.questionActive.question_use_hint && clickStore.questionActive.question_hint" class="text-center mb-2 text-sm">
            <div v-if="clickStore.questionActive.showing_hint" class="text-white" text @click.stop="clickStore.questionActive.showing_hint = false"> 
              {{ clickStore.questionActive.question_hint }}
            </div>
            <button v-else @click.stop="clickStore.questionActive.showing_hint = true" size="small" class="text-white items-top mx-auto" text>
              <div class="flex items-center justify-center"><ViewIcon class="size-4 mr-1 text-white " />
                {{ localize('show_hint') }}
              </div>
            </button>
          </div>
          <!-- TODO move into own component -->
          <!-- Free response -->
          <div v-if="clickStore.questionActive.question_type == 'free'" class="w-full max-w-md mx-auto">
            <TextArea v-model="response" :placeholder="localize('enter_response')" class="w-full" rows="1" auto-resize
              :autofocus="!props.readonly" />
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'number'" class="flex justify-center">
            <InputNumber v-model="response" :placeholder="localize('enter_response')"
              class="w-fit text-2xl !text-center !mx-auto" :autofocus="!props.readonly" />
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'date'"
            class="flex justify-center max-w-sm mx-auto !text-center">
            <DatePicker v-model="response" :placeholder="localize('choose_date')" class="w-full p-0 !text-center" id="question-layer-datepicker"
              dateFormat="mm/dd/yy" :autofocus="!props.readonly" />
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'all'"
            class="grid grid-cols-2 gap-3 items-top w-full fill-height">
            <div v-for="(answer, index) in answerChoices" :key="answer.id" class="col-span-1 "
              :class="{ 'text-right': index % 2 == 0, 'text-left': index % 2 == 1 }">
              <div v-if="answer.text" class="flex items-top"
                :class="{ 'justify-end': index % 2 == 0, 'justify-start': index % 2 == 1 }">
                <label v-if="index % 2 == 0" :for="answer.text" class="mr-2">{{ answer.text }}</label>
                <Checkbox :value="answer.text" v-model="response" />
                <label v-if="index % 2 == 1" :for="answer.text" class="ml-2">{{ answer.text }}</label>
              </div>
            </div>
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'select'" class="flex justify-center text-center">
            <Select v-model="response" :options="answerChoices" optionLabel="text" :filter="answerChoices.length > 6"
              dropdown resetFilterOnHide autoFilterFocus autoOptionFocus class="w-full text-sm max-w-sm"
              :placeholder="localize('select_answer')" />
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'rating'" class="max-w-sm mx-auto">
            <div class="grid grid-cols-5 items-center justify-center">
              <div v-for="i in 5" :key="i" class="col-span-1">
                <!-- TODO colors -->
                <StarIcon class="w-12 h-12 mx-auto cursor-pointer" @click.stop="handleRating(i)" />
              </div>
            </div>
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'likert'"
            class="grid grid-cols-2 gap-3 max-w-md mx-auto">
            <div class="col-span-1"><Button @click.stop="handleLikerlocalize('Strongly Agree')" class="w-full" size="large"
                :disabled="disabledResponses">{{ localize('strongly_agree') }}</Button></div>
            <div class="col-span-1"><Button @click.stop="handleLikerlocalize('Agree')" class="w-full" size="large"
                :disabled="disabledResponses">{{ localize('agree') }}</Button></div>
            <div class="col-span-2"><Button @click.stop="handleLikerlocalize('Neutral')" class="w-full" size="large"
                :disabled="disabledResponses">{{ localize('neutral') }}</Button></div>
            <div class="col-span-1"><Button @click.stop="handleLikerlocalize('Disagree')" class="w-full" size="large"
                :disabled="disabledResponses">{{ localize('disagree') }}</Button></div>
            <div class="col-span-1"><Button @click.stop="handleLikerlocalize('Strongly Disagree')" class="w-full" size="large"
                :disabled="disabledResponses">{{ localize('strongly_disagree') }}</Button></div>
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'video'"
            class="w-full max-w-md mx-auto text-center">
            <Button size="large" severity="info" @click.stop="startAnswerVideo">{{ localize('record_response') }}</Button>
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'voice'"
            class="w-full max-w-md mx-auto text-center">
            <Button size="large" severity="info" @click.stop="startAnswerAudio">{{ localize('record_response') }}</Button>
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'image'"
            class="w-full max-w-md mx-auto text-center">
            <Suspense>
              <ImageResponse @success="handleImageSuccess" class="w-full h-32 mx-auto" />
            </Suspense>
          </div>
          <div v-else-if="clickStore.questionActive.question_type == 'draw'"
            class="w-full max-w-md mx-auto text-center">
            <Button size="large" severity="info" @click.stop="startAnswerDrawing">{{ localize('draw') }}</Button>
          </div>
          <div v-else-if="['multiple','poll'].includes(clickStore.questionActive.question_type)" class="w-full max-w-md mx-auto">
            <div class="grid grid-cols-2 gap-4 items-center w-full max-w-md fill-height">
              <div v-for="(answer, index) in answerChoices" :key="answer.id" class="col-span-1 items-center">
                <div v-if="answer.text" class="flex items-center justify-center">
                  <Button size="large" class="w-full tracking-wide" @click.stop="handleAnswerClick(answer)"
                    :disabled="disabledResponses">{{ answer.text }}</Button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showSubmitButton" class="mt-4 w-full text-center">
            <Button size="large" severity="info" :disabled="!canSubmit" @click.stop="submitResponse">{{ localize('continue')
              }}</Button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Skip button -->
    <div v-if="showSkipButton" class="absolute top-0 right-0">
      <Button size="small" text @click.stop="skipQuestion"
        v-tooltip.left="editManage ? localize('skip_as_owner') : localize('skip')">
        <ArrowRight02Icon class="inline-block size-8 text-black" />
      </Button>
    </div>

    <!-- Rewind button -->
    <div v-if="showRewindButton"  class="absolute top-0 left-0">
      <Button size="small" text @click.stop="rewindQuestion"
        v-tooltip.right="localize('rewind')">
        <ArrowLeft02Icon class="inline-block size-8 mr-1 text-white " />
      </Button>
    </div>
  </div>
</template>


