<script setup>

const props = defineProps({
  ann: {
    type: Object,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

import { VideoStore } from '@/front/stores/video_store.js';
import { PlayerStore } from '@/front/stores/player_store.js';
import { AnnStore } from '@/front/stores/ann_store.js';
const annStore = AnnStore();
import { PositionCalculator } from '@/front/composables/PositionCalculator.js';
const { calculatePosition } = PositionCalculator()

const videoStore = VideoStore();
const playerStore = PlayerStore();

const customStyle = props.ann.customStyle && JSON.parse(props.ann.customStyle) || {}
console.log(customStyle)
const styling = computed(() => {
  const baseDesign = videoStore.video.design || {}
  const widthRatio = playerStore.playerState.width / customStyle.videoWidth
  let bgColor = props.ann.background_color || customStyle.background_color || baseDesign.comment_background_color
  let textColor = props.ann.text_color || customStyle.color || baseDesign.comment_text_color
  let opacity = props.disabled ? 0.5 : 1
  let cursor = props.disabled ? 'not-allowed' : 'pointer'
  
  if (props.ann.post_click_state == "color" && props.ann.clicked) {
    bgColor = props.ann.post_click_color
    textColor = props.ann.post_click_text_color
  }
  // TODO make sure this doesnt break in diff context
  const dimensions = calculatePosition(props.ann)
  return {
    shell: {
      'width': `${dimensions.width}px`,
      'height': `${dimensions.height}px`,
      'left': `${dimensions.left}px`,
      'top': `${dimensions.top}px`,
      'minHeight': `${dimensions.minHeight}px`,
      'fontSize': `${dimensions.fontSize}px`,
      'textAlign': customStyle.textAlign || 'center',
      // TODO line height, make sure doesnt mess up old ones
    },
    content: {
      'background': props.ann.background_color || customStyle.background_color || baseDesign.comment_background_color,
      'padding': '0.2em 0.5em',
      'width': 'fit-content',
      'margin': props.ann.text_align == 'left' ? '0 0 0 0' : props.ann.text_align == 'right' ? '0 0 0 auto' : '0 auto',
      'color': textColor,
      'background': bgColor,
      'borderColor': props.ann.borderColor || customStyle.borderColor || baseDesign.comment_border_color || 'red',
      'borderRadius': widthRatio * ((props.ann.border_radius || customStyle.borderRadius || baseDesign.comment_border_radius) / 10) + 'em',
      'fontWeight': 'bold',
      'opacity': opacity,
      'cursor': cursor,
    }
  }
})
</script>

<template>

  <div :style="styling.shell" :class="annStore.isEditing ?  'animate-none' : `${ann.animation} hover:scale-105 z-10`">
    <!-- TODO flex bad for existing interactions? -->
    <div class="text-content" :style="styling.content" v-html="annStore.isEditing ? ann.content : ann.transformed_content"></div>
  </div>

</template>