/**
 * Format a decimal time value into a padded key
 * @param {number} decitime - The decimal time value
 * @returns {string|null} Padded time key or null
 */
export function convertDecitimeToKey(decitime) {
  if (decitime == undefined) return null
  let [seconds, tenths] = decitime.toString().split('.')

  seconds = seconds.padStart(6, '0')
  tenths = tenths ? tenths.padEnd(1, '0') : '0'

  return seconds + tenths
}

/**
 * Extract domain from URL without protocol and www
 * @param {string} url - The URL to process
 * @returns {string} Domain name
 */
export function getDomain(url) {
  return url
    .replace('https://', '')
    .replace('http://', '')
    .replace('www.', '')
    .split('/')[0]
}

/**
 * Extract base domain/hostname from URL
 * @param {string} url - The URL to process
 * @returns {string|null} Hostname or null if invalid
 */
export function getBaseDomain(url) {
  if (!url || !url.includes('http')) {
    return url
  }
  
  try {
    const { hostname } = new URL(url)
    return hostname
  } catch (error) {
    console.error("Invalid URL:", error)
    return null
  }
}

/**
 * Format a number with K suffix for thousands
 * @param {number} number - Number to format
 * @returns {string} Formatted number
 */
export function formatNumber(number) {
  if (number == undefined) {
    return 0
  }
  
  if (number >= 9999) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  } else if (number >= 1000) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  
  return number.toString()
}

/**
 * Format seconds into HH:MM:SS.D format
 * @param {number} seconds - Seconds to format
 * @param {boolean} decimals - Include decimal places
 * @returns {string} Formatted time string
 */
export function formatSeconds(seconds, decimals = true) {
  if (seconds < 0 || seconds == undefined) {
    return ''
  }

  const date = new Date(seconds * 1000)
  let hh = date.getUTCHours()
  let mm = date.getUTCMinutes()
  let ss = date.getSeconds()
  let ms = date.getMilliseconds()

  // Format hours
  hh = hh > 0 ? hh + ":" : ""

  // Format minutes
  if (mm < 10 && mm != 0) {
    mm = hh === "" ? mm + ":" : "0" + mm + ":"
  } else if (mm >= 10 || mm === 0) {
    mm = mm + ":"
  }

  // Format seconds
  ss = ss < 10 ? "0" + ss : ss

  // Format milliseconds
  if (decimals) {
    ms = ms === 0 ? '' : '.' + ms.toString().substring(0, 1)
  } else {
    ms = ''
  }

  return hh + mm + ss + ms
}

/**
 * Strip HTML tags and JavaScript from a string
 * @param {string} str - String to clean
 * @returns {string} Cleaned string
 */
export function stripHtmlAndJs(str) {
  if (!str) return ''
  
  // Remove HTML tags
  let stripped = str.replace(/<[^>]*>/g, '')
  
  // Remove JavaScript
  stripped = stripped.replace(/javascript:/gi, '')
  stripped = stripped.replace(/on\w+\s*=\s*["']?[^"']*["']?/gi, '')
  
  // Decode HTML entities
  const textarea = document.createElement('textarea')
  textarea.innerHTML = stripped
  return textarea.value
}