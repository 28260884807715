<script setup>
import { ClickStore } from "@/front/stores/click_store.js";
import { PlayerStore } from "@/front/stores/player_store.js";
import { VideoStore } from "@/front/stores/video_store.js";
import { defineAsyncComponent } from 'vue'

// Critical components - load immediately
import IntLayer from "@/front/components/IntLayer.vue";
import DialogLayer from "@/front/components/DialogLayer.vue";
import IntroLayer from "@/front/components/IntroLayer.vue";

// Deferred components - load when needed
const PayLayer = defineAsyncComponent(() => 
  import("@/front/components/PayLayer.vue")
)
const DrawCreationLayer = defineAsyncComponent(() => 
  import("@/front/components/DrawCreationLayer.vue")
)
const GuideLayer = defineAsyncComponent(() => 
  import("@/front/components/GuideLayer.vue")
)
const EndScreenLayer = defineAsyncComponent(() => 
  import("@/front/components/EndScreenLayer.vue")
)
const MagicMenuLayer = defineAsyncComponent(() => 
  import("@/front/components/MagicMenuLayer.vue")
)
const ScreenshotLayer = defineAsyncComponent(() => 
  import("@/front/components/ScreenshotLayer.vue")
)
const ResumeLayer = defineAsyncComponent(() => 
  import("@/front/components/ResumeLayer.vue")
)

// Store initialization
const clickStore = ClickStore();
const playerStore = PlayerStore();
const videoStore = VideoStore();

// Computed properties
const showIntroLayer = computed(() => {
  return playerStore.initialized && 
         !playerStore.started && 
         !clickStore.hasActiveDialog && 
         !clickStore.magicMenuActive
})

const props = defineProps({
  isMenu: Boolean,
  isEnd: Boolean,
  isNormal: Boolean,
});
</script>

<template>
  <div id="master-layer">
    <Transition
      name="fade"
      class="mb-10"
      style="--animate-duration: 500ms;"
    >
      <Suspense v-if="clickStore.payDialog">
        <PayLayer />
        <template #fallback>
          <div class="loading-placeholder"></div>
        </template>
      </Suspense>
    </Transition>

    <Suspense v-if="clickStore.isDrawing && !clickStore.questionActive">
      <DrawCreationLayer />
    </Suspense>

    <Suspense v-else-if="clickStore.guideActive">
      <GuideLayer />
    </Suspense>

    <IntLayer
      v-else-if="!playerStore.error && isNormal && playerStore.ready"
      v-show="!clickStore.hasActiveDialog && playerStore.started"
    />

    <Suspense v-else-if="isEnd">
      <EndScreenLayer />
    </Suspense>

    <Suspense v-else-if="isMenu">
      <MagicMenuLayer />
    </Suspense>
    
    <DialogLayer v-if="playerStore.started" />

    <Suspense v-else-if="clickStore.resumeDialog">
      <ResumeLayer />
    </Suspense>

    <Suspense v-else-if="showIntroLayer">
      <IntroLayer />
    </Suspense>

    <Transition
      :name="videoStore.video.design.switch_transition || 'fade'"
      tag="div"
      :duration="{ enter: 0, leave: 1000 }"
      :enter-active-class="''"
      :enter-from-class="''"
      :enter-to-class="''"
    >
      <Suspense v-if="playerStore.branching">
        <ScreenshotLayer />
      </Suspense>
    </Transition>
  </div>
</template>

<style scoped>
.loading-placeholder {
  /* Add any loading placeholder styles */
  min-height: 100px;
}
</style>