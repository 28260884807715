<script setup>
import { VideoStore } from '@/front/stores/video_store.js'
import InputText from 'primevue/inputtext';
const videoStore = VideoStore();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const password = ref('');
const email = ref('');
const code = ref('');
const disabled = ref(false);

const checkPassword = () => {
  disabled.value = true;
  videoStore.checkPassword(password.value).then((valid) => {
    console.log(valid)
    console.log("🔴 Password valid", valid);
    if (valid) {
      videoStore.show(videoStore.video.id, { manage: false, password: password.value }).then(() => {
      })
    } else {
      console.log("🔴 Password incorrect");
      toast.add({
        severity: 'error',
        detail: t('common.password_incorrect'),
        life: 5000
      });
    }
  }).catch((error) => {
    console.log("🔴 Password incorrect", error);
  }).finally(() => {
    password.value = '';
    setTimeout(() => {
      disabled.value = false;
    }, 5000);
  })
}

const checkCode = () => {
  disabled.value = true;
  videoStore.checkCode(email.value, code.value).then((data) => {
    console.log(data)
    console.log("🔴 Code valid", data.item_id);
    if (data.item_id) {
      videoStore.show(videoStore.video.id, { manage: false, item_id: data.item_id }).then(() => {
      })
    } else {
      toast.add({
        severity: 'error',
        detail: t('common.invalid_credentials'),
        life: 5000
      });
    }
  }).catch((error) => {
    console.log("🔴 Password incorrect", error);
  }).finally(() => {
    setTimeout(() => {
      disabled.value = false;
    }, 5000);
  })
}

</script>

<template>
  <div class="h-full w-full flex flex-col items-center justify-center bg-black text-center text-white">

    
    <div v-if="videoStore.video.accessible.list">
      <div class="text-2xl font-bold mb-4"> {{ $t('common.authentication_required') }} </div>
      <InputText type="email" v-model="email" :placeholder="$t('common.enter_email')" class="mb-4"  autocomplete="off" autofocus/>
      <InputText type="text" v-model="code" :placeholder="$t('common.enter_code')" class="mb-4"  autocomplete="off" autofocus/>
      <Button @click="checkCode" class="!bg-[var(--user-theme-color)]" :disabled="disabled">{{ t('common.submit') }}</Button>
    </div>
    <div v-else-if="videoStore.video.accessible.password">
      <div class="text-2xl font-bold mb-4"> {{ $t('common.password_required') }} </div>
      <InputText type="password" v-model="password" :placeholder="$t('common.enter_password')" class="mb-4"  autocomplete="off" autofocus/>
      <Button @click="checkPassword" class="!bg-[var(--user-theme-color)]" :disabled="disabled">{{ t('common.submit') }}</Button>
    </div>
    <div v-else>
      <a href="https://mindstamp.com" target="_blank"> 
        <img 
          v-if="videoStore.video.accessible.expired"
        class="h-8 mb-4" 
        src="https://resource-cdn.mindstamp.com/assets/images/shake.gif" 
          v-tooltip="'Mindstamp: The Interactive Video Platform ⚡️'"
        >
      </a>

      <div class="text-3xl font-bold mb-4"> {{ videoStore.video.title }} </div>
      <div class="text-lg"> {{ $t('common.video_not_available') }}:
        <span v-if="videoStore.video.accessible.unpublished"> {{ $t('common.not_published') }} </span>
        <span v-if="videoStore.video.accessible.whitelist"> Code 617 </span>
        <span v-else-if="videoStore.video.accessible.embed"> {{ $t('common.embed_only') }} </span>
        <span v-else-if="videoStore.video.accessible.expired"> {{ $t('common.expired') }} </span>
        <span v-else-if="videoStore.video.accessible.paused"> {{ $t('common.paused') }} </span>
      </div>
      <div class="text-xs mt-4">{{ videoStore.video.token }} </div>
    </div>
  </div>
</template>

