import { AnnStore } from '@/front/stores/ann_store.js'
import { PlayerStore } from '@/front/stores/player_store.js'
import { PlaybackStore } from '@/front/stores/playback_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
import { ClickStore } from '@/front/stores/click_store.js'
import { AudioStore } from '@/front/stores/audio_store.js'
import { AssetStore } from '@/front/stores/asset_store.js'
import { ClipStore } from '@/front/stores/clip_store.js'
import { LeadCaptureStore } from '@/front/stores/lead_capture_store.js'
import { useRouter } from 'vue-router'
import { BrowserHelper } from '@/front/composables/BrowserHelper.js'
import { fixUrl } from '@/front/composables/StringComposer'
import { focus } from '@/front/composables/UxComposer'
import { VideoService } from '@/front/services'
import { MindscriptService } from '@/front/services'

const { browserInfo } = BrowserHelper()

// Store initialization helper
function getStores() {
  return {
    annStore: AnnStore(),
    assetStore: AssetStore(),
    playerStore: PlayerStore(),
    playbackStore: PlaybackStore(),
    videoStore: VideoStore(),
    clickStore: ClickStore(),
    audioStore: AudioStore(),
    leadCaptureStore: LeadCaptureStore(),
    router: useRouter()
  }
}

/**
 * Track a click interaction
 */
export function trackClick(ann) {
  const { playbackStore, videoStore } = getStores()
  playbackStore.safeTrackViewerClick(videoStore.video, ann)
}

/**
 * Evaluate and set a variable
 */
export function doVariableEvaluation(variable, value) {
  const { playbackStore } = getStores()
  
  if (variable && value) {
    if (value.startsWith("{{") && value.endsWith("}}")) {
      const result = MindscriptService.evaluateExpression(value, playbackStore)
      playbackStore.setVariable(variable, result)
    } else {
      playbackStore.setVariable(variable, value)
    }
  }
}

/**
 * Submit an interaction response
 */
export function submitResponse(ann, response) {
  const { leadCaptureStore, playbackStore, videoStore } = getStores()
  
  leadCaptureStore.handleInteractionClick(ann.id)
  doVariableEvaluation(ann.variable, response)
  playbackStore.safeTrackResponse(videoStore.video, ann, response)
}

/**
 * Handle clicking an interaction
 */
export function clickInteraction(ann, completeAction = true) {
  const { clickStore, leadCaptureStore, annStore } = getStores()

  clickStore.clearTimedPause()
  leadCaptureStore.handleInteractionClick(ann.id)
  trackClick(ann)
  
  ann.clicked = true
  annStore.pausedInteraction = null

  doVariableEvaluation(ann.variable, ann.value)

  if (completeAction) {
    doAction(ann)
  }

  annStore.resetMustClickInteractions()
  annStore.resetActive()
}

/**
 * Perform the action associated with an interaction
 */
export function doAction(ann) {
  const actionMap = {
    'video': () => {
      const token = ann.click_value.split("---")[1]
      doVideoBranch(token)
    },
    'message': () => showMessage(ann),
    'link': () => doOpenLink(ann),
    'jump': () => doJump(ann),
    'back': () => {
      const { playbackStore } = getStores()
      doVideoBranch(playbackStore.refer_video)
    },
    'view': () => doImage(ann.click_value),
    'article': () => doArticle(ann),
    'genie': () => doGenie(ann),
    'openai': () => doChatGPT(ann),
    'email': () => doEmail(ann),
    'phone': () => doPhone(ann),
    'modal': () => startLink(ann.click_value),
    'collect': () => doCollect(ann),
    'download': () => doDownload(ann),
    'resourceTray': () => openMagicMenu(ann),
    'clip': () => doClip(ann),
    'audio': () => doAudio(ann)
  }

  const action = actionMap[ann.click_action]
  if (action) action()
}

export function submitCollect(video, answer) {
  alert("TODO submitCollect")
}

// Audio handling
export async function doAudio(ann) {
  const { playerStore, clickStore, audioStore } = getStores()
  
  playerStore.pause("clickComposer doAudio")
  clickStore.startAudio()
  await audioStore.streamById(ann.click_value)
}

export function finishAudio() {
  const { clickStore, annStore } = getStores()
  clickStore.stopAudio()
  annStore.tryFinishQuestion()
}

// Clip handling
export async function doClip(ann) {
  const { playerStore, clickStore } = getStores()
  const clipStore = ClipStore()
  
  playerStore.pause("clickComposer doClip")
  clickStore.startClip()
  await clipStore.streamById(ann.click_value)
}

export function finishClip() {
  const { clickStore, playerStore } = getStores()
  const clipStore = ClipStore()
  
  clickStore.stopClip()
  clipStore.clear()
  playerStore.play("clickComposer finishClip")
}

// Magic Menu handling
export function openMagicMenu(ann) {
  const { playerStore, clickStore } = getStores()
  
  playerStore.pause("clickComposer openMagicMenu")
  clickStore.showMagicMenu(ann.click_value)
}

export function finishMagicMenu() {
  const { clickStore, playerStore } = getStores()
  
  clickStore.hideMagicMenu()
  playerStore.play("clickComposer finishMagicMenu")
}

// Guide handling
export function openGuide(ann) {
  const { playerStore, clickStore } = getStores()
  
  playerStore.pause("clickComposer openGuide")
  clickStore.showGuide(ann.click_value)
  focus("guide-search", 250)
}

export function finishGuide() {
  const { clickStore, playerStore } = getStores()
  
  clickStore.hideGuide()
  playerStore.play("clickComposer finishGuide")
}

// File handling
export function doDownload(ann) {
  if (browserInfo.isIOS) {
    const { playerStore } = getStores()
    playerStore.pause("clickComposer doDownload")
    safeOpenLink(ann.download_url, false)
  } else {
    downloadFile(ann.download_url)
  }
}

export function downloadFileFromUrl(url, filename = null) {
  if (browserInfo.isIOS) {
    const { playerStore } = getStores()
    playerStore.pause("clickComposer doDownload")
    safeOpenLink(url, false)
  } else {
    downloadFile(url, filename)
  }
}

// Chat and interaction handling
export function doChatGPT(ann) {
  const { playerStore, clickStore } = getStores()
  
  playerStore.pause("clickComposer doChatGPT")
  clickStore.startChatGPT()
}

export function finishChatGPT() {
  const { clickStore, playerStore } = getStores()
  
  clickStore.stopChatGPT()
  playerStore.play("clickComposer finishChatGPT")
}

export function doGenie(ann) {
  const { playerStore, clickStore } = getStores()
  
  playerStore.pause("clickComposer doGenie")
  clickStore.startGenie()
}

export function finishGenie() {
  const { clickStore, playerStore } = getStores()
  
  clickStore.stopGenie()
  playerStore.play("clickComposer finishGenie")
}

// Collection and navigation
export function doCollect(ann) {
  const { playerStore, clickStore } = getStores()
  
  playerStore.pause("clickComposer doCollect")
  clickStore.startCollect(ann.click_value)
}

export function finishCollect() {
  const { clickStore, playerStore } = getStores()
  
  clickStore.stopCollect()
  playerStore.play("clickComposer finishCollect")
}

export function doJump(ann) {
  const { clickStore, playerStore } = getStores()
  
  clickStore.resetAll()
  playerStore.seekAndPlay(ann.click_value)
}

// Media handling
export function doImage(url) {
  const { playerStore, clickStore } = getStores()
  
  playerStore.pause("clickComposer doImage")
  clickStore.setActiveImageUrl(url)
}

export function finishImage() {
  const { clickStore, playerStore } = getStores()
  
  clickStore.clearActiveImageUrl()
  playerStore.play("clickComposer finishImage")
}

export function doArticle(ann) {
  const { playerStore, clickStore, assetStore } = getStores()
  
  playerStore.pause("clickComposer doArticle")
  assetStore.getArticle(ann.click_value).then(article => {
    clickStore.setActiveArticle(article)
  })
}

export function showMessage(ann) {
  const { playerStore, clickStore } = getStores()
  
  playerStore.pause("clickComposer showMessage")
  clickStore.setActiveMessage(ann.click_value)
}

export function finishMessage() {
  const { clickStore, annStore } = getStores()
  
  clickStore.clearActiveMessage()
  annStore.tryFinishQuestion()
}

export function finishArticle() {
  const { clickStore, playerStore } = getStores()
  
  clickStore.clearActiveArticle()
  playerStore.play("clickComposer finishArticle")
}

// Link handling
export function startLink(url) {
  const { clickStore } = getStores()
  clickStore.startLink(url)
}

export function finishLink() {
  const { clickStore, annStore, playerStore } = getStores()
  
  clickStore.stopLink()
  annStore.resetActive()
  playerStore.play("clickComposer finishLink")
}

export function doEmail(ann) {
  const { playerStore } = getStores()
  
  playerStore.pause("clickComposer doEmail")
  const url = "mailto:" + ann.click_value
  safeOpenLink(url, false)
}

export function doPhone(ann) {
  const { playerStore, clickStore } = getStores()
  playerStore.pause("clickComposer doPhone")
  if (browserInfo.isMobile) {
    const url = "tel:" + ann.click_value
    safeOpenLink(url, false)
  } else {
    // TODO localization message for phone
    let message = ann.click_value
    clickStore.setActiveMessage(message)
  }
}

export function doOpenLink(ann) {
  const { playerStore } = getStores()
  
  playerStore.pause("clickComposer doOpenLink")
  safeOpenLink(ann.click_value, false)
}

export function doVideoBranch(token) {
  console.log("🔎 ClickComposer doVideoBranch", token)
  const { clickStore, router, playerStore, playbackStore, videoStore } = getStores()
  
  clickStore.resetAll()
  VideoService.branchToVideo(token, {
    router,
    player: playerStore,
    playback: playbackStore,
    video: videoStore
  })
}

/**
 * Safely open a URL in a new tab or current window
 */
export function safeOpenLink(url, sameTab = false) {
  if (typeof url !== 'string' || !url.trim()) {
    console.error('openLink: Invalid URL provided.')
    return false
  }

  url = fixUrl(url)

  try {
    new URL(url)
  } catch (e) {
    console.error('openLink: Malformed URL.')
    return false
  }

  if (sameTab) {
    window.location.href = url
    return true
  }

  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) {
    newWindow.opener = null
    return true
  }
  
  console.warn('openLink: Unable to open a new tab. Pop-up blocker might be preventing this action.')
  return false
}

/**
 * Download a file from a URL
 */
export async function downloadFile(url, filename = null) {
  try {
    if (typeof url !== 'string' || !url.trim()) {
      throw new Error('Invalid URL provided.')
    }

    if (!filename) {
      const urlParts = url.split('/')
      filename = urlParts[urlParts.length - 1].split('?')[0]
      if (!filename) {
        throw new Error('Unable to determine filename from URL.')
      }
    }

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
    })

    if (!response.ok) {
      throw new Error(`Failed to fetch the file. Status: ${response.status}`)
    }

    const blob = await response.blob()

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
      return
    }

    const blobUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = blobUrl
    a.download = filename
    document.body.appendChild(a)
    a.style.display = 'none'
    a.click()
    a.remove()
    window.URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error('Download failed:', error)
    alert(`Failed to download the file: ${error.message}`)
    throw error
  }
}