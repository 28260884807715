<script setup>

const props = defineProps({
  ann:{
    type: Object,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
import { AnnStore } from '@/front/stores/ann_store.js';
const annStore = AnnStore();

import { PositionCalculator } from '@/front/composables/PositionCalculator.js';
const { calculatePosition } = PositionCalculator()

const customStyle = props.ann.customStyle && JSON.parse(props.ann.customStyle) || {}
const styling = computed(() => {
  const dimensions = calculatePosition(props.ann)
  let opacity = props.disabled ? 0.5 : 1
  let cursor = props.disabled ? 'not-allowed' : 'pointer'
  return {
    'width': `${dimensions.width}px`,
    'height': `${dimensions.height}px`,
    'left': `${dimensions.left}px`,
    'top': `${dimensions.top}px`,
    'minHeight': `${dimensions.minHeight}px`,
    'fontSize': `${dimensions.fontSize}px`,
    'minHeight': '',
    'padding': 0,
    'opacity': opacity,
    'cursor': cursor,
  }
})
</script>

<template>
  
  <div class="w-full h-full" :style="styling">
    <img :src="ann.content" :class="annStore.isEditing ?  'animate-none' : `${ann.animation} hover:scale-105 z-10`"/>
    
    <!-- TOOD check size and show setting -->
    <div v-if="ann.extra" class="text-center text-2xl my-2">
      {{ ann.extra }}
    </div>
  </div>

</template>