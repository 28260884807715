<script setup>
import { PlayerStore } from '@/front/stores/player_store.js'
import { ClickStore } from '@/front/stores/click_store.js'
import { AnnStore } from '@/front/stores/ann_store.js'
import { DesignStore } from '@/front/stores/design_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
import { defineAsyncComponent } from 'vue'
import { Cancel01Icon } from 'hugeicons-vue'
import ProgressBar from 'primevue/progressbar'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

// Store initialization
const playerStore = PlayerStore()
const clickStore = ClickStore()
const designStore = DesignStore()
const annStore = AnnStore()
const videoStore = VideoStore()

// Lazy load all components
const ErrorLayer = defineAsyncComponent(() => 
  import('@/front/components/ErrorLayer.vue')
)
const MessageLayer = defineAsyncComponent(() => 
  import('@/front/components/MessageLayer.vue')
)
const MagicMenuLayer = defineAsyncComponent(() => 
  import('@/front/components/MagicMenuLayer.vue')
)
const ImageLayer = defineAsyncComponent(() => 
  import('@/front/components/ImageLayer.vue')
)
const ArticleLayer = defineAsyncComponent(() => 
  import('@/front/components/ArticleLayer.vue')
)
const GenieLayer = defineAsyncComponent(() => 
  import('@/front/components/GenieLayer.vue')
)
const ChatGPTLayer = defineAsyncComponent(() => 
  import('@/front/components/ChatGPTLayer.vue')
)
const CollectLayer = defineAsyncComponent(() => 
  import('@/front/components/CollectLayer.vue')
)
const ClipLayer = defineAsyncComponent(() => 
  import('@/front/components/ClipLayer.vue')
)
const AudioLayer = defineAsyncComponent(() => 
  import('@/front/components/AudioLayer.vue')
)
const DrawViewLayer = defineAsyncComponent(() => 
  import('@/front/components/DrawViewLayer.vue')
)
import QuestionLayer from '@/front/components/QuestionLayer.vue'

const CaptureLeadLayer = defineAsyncComponent(() => 
  import('@/front/components/CaptureLeadLayer.vue')
)

const questionLayerVisible = computed(() => {
  return !clickStore.activeMessage && !clickStore.clipActive
})
</script>

<template>
  <span id="dialog-layer"></span>
  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.questionActive && !clickStore.clipActive">
      <QuestionLayer :readonly="annStore.isQuestion" />
      <template #fallback>
        <div class="loading-state"></div>
      </template>
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="playerStore.error">
      <ErrorLayer />
    </Suspense>
  </Transition>

  <Transition :name="videoStore.video.design.message_transition || 'fade'" mode="in" appear style="--animate-duration: 0.5s;">
    <Suspense v-if="clickStore.activeMessage">
      <MessageLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.activeImageUrl">
      <ImageLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.activeArticle">
      <ArticleLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.genieActive">
      <GenieLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.chatGPTActive">
      <ChatGPTLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.activeCollect">
      <CollectLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.magicMenuActive && !clickStore.activeMessage">
      <MagicMenuLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="!annStore.isEditing && (clickStore.clipActive || clickStore.interactionClipActive)">
      <ClipLayer :ann="clickStore.activeClipAnn"/>
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.audioActive">
      <AudioLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.captureLeadActive">
      <CaptureLeadLayer />
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <Suspense v-if="clickStore.activeDrawing">
      <DrawViewLayer class="w-full h-full"/>
    </Suspense>
  </Transition>

  <Transition name="fade" mode="out-in" appear style="--animate-duration: 0.2s;">
    <div v-if="clickStore.activeTimedPause" id="pause-progress" class="w-full absolute top-0 left-0 h-2">
      <div class="w-full h-full" :style="{ width: clickStore.activePauseAmounts.progress + '%', backgroundColor: designStore.design.color }"></div>
    </div>
  </Transition>

  <!-- Modal embed -->
  <Dialog v-model:visible="clickStore.activeLink" id="link-dialog" modal class="w-[95vw] h-[95vh] select-none bg-black">
    <iframe :src="clickStore.activeLink" class="w-full h-full fixed left-0 top-0 rounded-lg" />
    <div class="fixed -top-5 -right-5 z-50">
      <Button @click="clickStore.stopLink()" rounded severity="info" class="p-2 text-white font-bold">
        <Cancel01Icon :size="20" class="text-red" />
      </Button>
    </div>
  </Dialog>
</template>

<style scoped>
.loading-state {
  @apply w-full h-full flex items-center justify-center;
  min-height: 50px;
}
</style>

<style>
/* Override default primevue dialog header */
#link-dialog [data-pc-section="header"] {
  @apply !hidden;
}
#link-dialog [data-pc-section="content"] {
  @apply !bg-black;
}
</style>