import { ref } from "vue";
import { PlayerStore } from "@/front/stores/player_store.js";
export function PositionCalculator() {
  const playerStore = PlayerStore();
  function calculatePosition(ann) {
    if (!ann) return {}
    const customStyle = ann.customStyle && JSON.parse(ann.customStyle) || {}
    const layerWidth = playerStore.layerDimensions.width
    const layerHeight = playerStore.layerDimensions.height
    
    // Calculate width and height while preserving aspect ratio for images
    let width, height
    if (ann.type_of === 'image' && ann.aspect_ratio) {
      // For images, calculate dimensions based on aspect ratio
      width = (ann.pos_width || customStyle.width) * 0.01 * layerWidth
      height = width / ann.aspect_ratio
      
      // If height exceeds bounds, recalculate based on height
      if (height > layerHeight * (ann.pos_height * 0.01)) {
        height = (ann.pos_height || customStyle.height) * 0.01 * layerHeight
        width = height * ann.aspect_ratio
      }
    } else if (ann.type_of === 'video' && ann.aspect_ratio) {
      // For images, calculate dimensions based on aspect ratio
      width = (ann.pos_width || customStyle.width) * 0.01 * layerWidth
      height = width / ann.aspect_ratio
      
      // If height exceeds bounds, recalculate based on height
      if (height > layerHeight * (ann.pos_height * 0.01)) {
        height = (ann.pos_height || customStyle.height) * 0.01 * layerHeight
        width = height * ann.aspect_ratio
      }
    } else {
      // For non-images, use standard calculation
      width = (ann.pos_width || customStyle.width) * 0.01 * layerWidth
      height = (ann.pos_height || customStyle.height) * 0.01 * layerHeight
    }
  
    const left = (ann.pos_left || customStyle.left) * 0.01 * layerWidth
    const top = (ann.pos_top || customStyle.top) * 0.01 * layerHeight
    const widthRatio = layerWidth / (ann.og_video_width || customStyle.videoWidth)
    const fontSize = (ann.font_size || customStyle.fontSize) * widthRatio
  
    return {
      width: Number(width.toFixed(2)),
      height: Number(height.toFixed(2)),
      minHeight: Number(Math.max(height, fontSize).toFixed(2)),
      left: Number(left.toFixed(2)),
      top: Number(top.toFixed(2)),
      fontSize: Number(fontSize.toFixed(2)),
    }
  }

  function getWidthRatio(ann) {
    const customStyle = ann.customStyle && JSON.parse(ann.customStyle) || {}
    return playerStore.layerDimensions.width / (ann.og_video_width || customStyle.videoWidth)
  }

  function pxToWidthPercent(px) {
    return Number((px / playerStore.layerDimensions.width * 100).toFixed(2))
  }

  function pxToHeightPercent(px) {
    return Number((px / playerStore.layerDimensions.height * 100).toFixed(2))
  }

  function getRelativeLayerDimensions(top,left,width,height) {
    // TODO weirdness top 0.625px?
    return {
      top: playerStore.layerDimensions.top  + (top/100) * playerStore.layerDimensions.height + 'px',
      left: playerStore.layerDimensions.left + (left/100) * playerStore.layerDimensions.width + 'px',
      width: playerStore.layerDimensions.width * (width/100) + 'px',
      height: playerStore.layerDimensions.height * (height/100) + 'px',
    }
  }


  return { calculatePosition, getWidthRatio, pxToWidthPercent, pxToHeightPercent, getRelativeLayerDimensions };
}