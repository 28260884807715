import { defineStore } from 'pinia'
import { PlaybackStore } from './playback_store.js'
import { ClickStore } from './click_store.js'
import { VideoStore } from './video_store.js'
import { PlayerStore } from './player_store.js'


export const PaymentStore = defineStore('payment', {
  state: () => ({
    complete: false, // Track if lead capture has been shown to prevent multiple shows
    fields: [],
    config: {
      type: null,  // 'start', 'timestamp', 'first_click', 'specific_interaction'
      timestamp: null,
      interactionId: null
    }
  }),

  actions: {
    setConfig(video) {
      const videoStore = VideoStore()

      // Check if we have a PLINK already that gives access
      let plink = null
      if (videoStore.video.plink) {
        console.log("💟 PaymentStore setConfig plink")
        plink = videoStore.video.plink
        let isActive = plink.active
        let isVideoMatch = plink.video_id == videoStore.video.id
        let notExpired = !plink.expires_at || new Date(plink.expires_at) > new Date()
        console.log("💟 PaymentStore setConfig plink", isActive, isVideoMatch, notExpired)
        if (isActive && isVideoMatch && notExpired) {
          this.complete = true
        } else {
          this.complete = false
        }
      }
      // Set config based on video settings
      console.log("💟 PaymentStore setConfig video.capture_auth_point", video.capture_auth_point)
      switch (video.capture_payment_point) {
        case -1:
          console.log("💟 PaymentStore setConfig dont_capture")
          this.complete = true
          break
        case 0:
          console.log("💟 PaymentStore setConfig start")
          this.config.type = 'start'
          break
        case 2:
          console.log("💟 PaymentStore setConfig timestamp")
          this.config.type = 'timestamp'
          this.config.timestamp = Number(video.capture_payment_value)
          break
      }
      console.log("💟 PaymentStore setConfig", this.config)
      // TODO check if field already met
      this.shown = false
    },

    checkTriggers(currentTime) {
      // console.log("💟 PaymentStore checkTriggers", currentTime, this.config.type, this.config.timestamp, this.complete)
      if (this.complete) return false// Don't show more than once
      
      const playbackStore = PlaybackStore()
      const videoStore = VideoStore()
      if (videoStore.video.manage) {
        return false
      }

      switch (this.config.type) {
        case 'start':
          if (currentTime < 0.5 && !playbackStore.leadCaptured) { // Show in first half second
            console.log("💟 LeadCaptureStore checkTriggers start", currentTime)
            this.showPaymentDialog()
          } else {
            return false
          }
          break

        case 'timestamp':
          console.log("💟 LeadCaptureStore checkTriggers timestamp", currentTime, this.config.timestamp, currentTime >= this.config.timestamp)
          if (currentTime >= this.config.timestamp && !playbackStore.leadCaptured) {
            this.showPaymentDialog()
          } else {
            return false
          }
          break
      }
      return false
    },
    showPaymentDialog() {
      const playerStore = PlayerStore()
      const clickStore = ClickStore()
      clickStore.payDialog = true
      playerStore.pause("PaymentStore showPaymentDialog")
    }
  }
})

// // In your AnnStore, add to updateActive:
// updateActive(time) {
//   const leadCaptureStore = LeadCaptureStore()
//   leadCaptureStore.checkTriggers(time)
  
//   // Rest of your existing updateActive code...
// }

// // In your ClickComposer, add to clickInteraction:
// function clickInteraction(ann) {
//   const leadCaptureStore = LeadCaptureStore()
//   leadCaptureStore.handleInteractionClick(ann.id)
  
//   // Rest of your existing clickInteraction code...
// }