<script setup>

const props = defineProps({
  ann: {
    type: Object,
    required: true
  },
})

import { VideoStore } from '@/front/stores/video_store.js';
import { PlayerStore } from '@/front/stores/player_store.js';
import { AnnStore } from '@/front/stores/ann_store.js';
const annStore = AnnStore();
import { PositionCalculator } from '@/front/composables/PositionCalculator.js';
const { calculatePosition } = PositionCalculator()
import { ClipStore } from '@/front/stores/clip_store.js';
const clipStore = ClipStore();
import ClipPlayer from '@/front/components/ClipPlayer.vue';
import Button from 'primevue/button';
import { Cancel01Icon } from 'hugeicons-vue';

const videoStore = VideoStore();
const playerStore = PlayerStore();

const customStyle = props.ann.customStyle && JSON.parse(props.ann.customStyle) || {}
console.log(customStyle)
const styling = computed(() => {
  const baseDesign = videoStore.video.design || {}
  const widthRatio = playerStore.playerState.width / customStyle.videoWidth
  // TODO make sure this doesnt break in diff context
  const dimensions = calculatePosition(props.ann)
  return {
    shell: {
      'width': `${dimensions.width}px`,
      'height': `${dimensions.height}px`,
      'left': `${dimensions.left}px`,
      'top': `${dimensions.top}px`,
      'minHeight': `${dimensions.minHeight}px`,
      'fontSize': `${dimensions.fontSize}px`,
      'textAlign': customStyle.textAlign || 'center',
      // TODO line height, make sure doesnt mess up old ones
    },
  }
})

const onClipEnded = () => {
  console.log("LayerClip onClipEnded")
  if (annStore.isEditing) {
    console.log("LayerClip onClipEnded isEditing cancelling")
    return
  }
  clipStore.clear()
  // TODO do we want this?
  playerStore.clipFinished()
}
</script>

<template>

  <div class="shadow-md" :style="styling.shell" :class="annStore.isEditing ?  'animate-none' : `${ann.animation} hover:scale-105 z-10`">
    <ClipPlayer v-if="clipStore.src" :clip="ann.content" :style="styling.content" :controls="false" @ended="onClipEnded" :loop="annStore.isEditing"/>
  </div>

</template>