import { useI18n } from 'vue-i18n'
import { formatSeconds } from '@/front/composables/TimeFormatter.js'
import { VideoStore } from '@/front/stores/video_store.js'

export function localize(str) {
  const videoStore = VideoStore();
  const { t } = useI18n({});
  const d = videoStore.video?.design;

  console.log("🌜 localize", str, d.auto_translate);

  // If auto-translate is enabled, use i18n translation
  // IF design doesn't exist, use i18n translation
  if (!d || d.auto_translate) {
    return t('common.' + str);
  }

  let val = ''

  switch (str) {
    case 'continue': val = d.continue_string; break;
    case 'skip': val = d.skip_string; break;
    case 'cancel': val = d.cancel_string; break;
    case 'correct': val = d.correct_string; break;
    case 'incorrect': val = d.incorrect_string; break;
    case 'your_answer': val = d.your_answer_string; break;
    case 'correct_answer': val = d.correct_answer_string; break;
    case 'question': val = d.question_string; break;
    case 'of': val = d.of_string; break;
    case 'add_comment': val = d.add_comment_string; break;
    case 'name': val = d.name_string; break;
    case 'email': val = d.email_string; break;
    case 'phone': val = d.phone_string; break;
    case 'custom_id': val = d.custom_id_string; break;
    case 'done': val = d.done_string; break;
    case 'close': val = d.close_string; break;
    case 'chapters': val = d.chapters_string; break;
    case 'interactions': val = d.interactions_string; break;
    case 'transcripts': val = d.transcripts_string; break;
    case 'search': val = d.search_string; break;
    case 'resume_view': val = d.resume_view_string; break;
    case 'resume_text': val = d.resume_text_string; break;
    case 'restart': val = d.restart_string; break;
    case 'try_again': val = d.try_again_string; break;
    case 'play': val = d.play_string; break;
    case 'pause': val = d.pause_string; break;
    case 'mute': val = d.mute_string; break;
    case 'unmute': val = d.unmute_string; break;
    case 'hint': val = d.hint_string; break;
    case 'show_hint': val = d.hint_string; break;
    case 'rewind': val = d.rewind_string; break;
    case 'color': val = d.color_string; break;
    case 'draw': val = d.draw_string; break;
    case 'text': val = d.text_string; break;
    case 'line': val = d.line_string; break;
    case 'pointer': val = d.pointer_string; break;
    case 'circle': val = d.circle_string; break;
    case 'arrow': val = d.arrow_string; break;
    case 'reset': val = d.reset_string; break;
    case 'exit': val = d.exit_string; break;
    case 'tool': val = d.tool_string; break;
    case 'show_resource_tray': val = d.show_resource_tray_string; break;
    case 'show_magic_menu': val = d.show_resource_tray_string; break;
    case 'show_guide': val = d.show_guide_string; break;
    case 'leaving': val = d.leaving_string; break;
    case 'show_cart': val = d.show_cart_string; break;
    case 'checkout': val = d.checkout_string; break;
    case 'payment_message': val = d.payment_message_string; break;
    case 'buy_now': val = d.buy_now_string; break;
    case 'show_magic_menu': val = d.show_resource_tray_string; break;
    case 'menu': val = d.menu_string; break;
    case 'guide': val = d.guide_string; break;
    case 'show_guide': val = d.show_guide_string; break;
    case 'help': val = d.help_string; break;
    case 'have_purchased': val = d.have_purchased_string; break;
    case 'choose_from_library': val = d.choose_from_library_string; break;
    case 'upload_new_video': val = d.upload_new_video_string; break;
    case 'record_new_video': val = d.record_new_video_string; break;
    case 'video_recording_not_supported': val = d.video_recording_not_supported_string; break;
    case 'recording_finished': val = d.recording_finished_string; break;
    case 'preview_not_supported': val = d.preview_not_supported_string; break;
    case 'save_video': val = d.save_video_string; break;
    case 'discard': val = d.discard_string; break;
    case 'recording_in_progress': val = d.recording_in_progress_string; break;
    case 'finish_recording': val = d.finish_recording_string; break;
    case 'start_recording': val = d.start_recording_string; break;
    case 'success': val = d.success_string; break;
    case 'video_clip_saved': val = d.video_clip_saved_string; break;
    case 'please_grant': val = d.please_grant_string; break;
    case 'continue_and_allow': val = d.continue_and_allow_string; break;
    case 'no_webcam': val = d.no_webcam_string; break;
    case 'no_microphone': val = d.no_microphone_string; break;
    case 'plug_in_devices': val = d.plug_in_devices_string; break;
    case 'yes': val = d.yes_string; break;
    case 'no': val = d.no_string; break;
    case 'add_clip_title': val = d.add_clip_title_string; break;
    case 'discard_video': val = d.discard_video_string; break;
    case 'add_audio': val = d.add_audio_string; break;
    case 'choose_existing_clip': val = d.choose_existing_clip_string; break;
    case 'record_voice_clip': val = d.record_voice_clip_string; break;
    case 'use_external_link': val = d.use_external_link_string; break;
    case 'browser_cant_record_audio': val = d.browser_cant_record_audio_string; break;
    case 'choose_audio_clip': val = d.choose_audio_clip_string; break;
    case 'enter_link': val = d.enter_link_string; break;
    case 'save': val = d.save_string; break;
    case 'upload_new_audio': val = d.upload_new_audio_string; break;
    case 'save_audio': val = d.save_audio_string; break;
    case 'audio_clip_saved': val = d.audio_clip_saved_string; break;
    case 'audio_recording_not_supported': val = d.audio_recording_not_supported_string; break;
    case 'discard_audio': val = d.discard_audio_string; break;
    case 'dial': val = d.dial_string; break;
    case 'afk': val = d.afk_string; break;
    case 'and': val = d.and_string; break;
    case 'or': val = d.or_string; break;
    case 'continue_viewing': val = d.continue_viewing_string; break;
    case 'continue_viewing_desc': val = d.continue_viewing_desc_string; break;
  }

  return val || t('common.' + str);
}

// String Transformations
export function transformContent(str, variables) {
  if (typeof str !== 'string') {
    throw new TypeError('First argument must be a string.')
  }

  if (typeof variables !== 'object' || variables === null) {
    throw new TypeError('Second argument must be a non-null object.')
  }

  const variablesLower = {}
  for (const key in variables) {
    if (Object.prototype.hasOwnProperty.call(variables, key)) {
      variablesLower[key.toLowerCase()] = variables[key]
    }
  }

  const regex = /{{\s*([a-zA-Z0-9_]+)\s*}}/gi
  return str.replace(regex, (match, p1) => {
    const key = p1.toLowerCase()
    return key in variablesLower ? variablesLower[key] : ''
  })
}

// URL Handling
export function addToUrl(url, str) {
  if (!url) return ''
  return url.includes('?') ? url + '&' + str : url + '?' + str
}

export function fixUrl(url) {
  if (!url) return ''
  if (url.startsWith('mailto:') || url.startsWith('tel:')) {
    return url
  }
  url = url.trim()
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url
  }
  return url
}

export function stringToQueryObject(str) {
  const params = new URLSearchParams(str)
  return Object.fromEntries(params.entries())
}

export function objectToQueryString(obj) {
  const filteredObj = Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== '')
  )
  const queryString = new URLSearchParams(filteredObj).toString()
  return queryString ? `?${queryString}` : ''
}

// File & Path Handling
export function getS3Key(url) {
  if (!url) return ''
  let parts = url.split('.com')
  let result = parts.length > 1 ? parts[1] : url
  parts = result.split('?')
  result = parts[0]
  return result.startsWith('/') ? result.slice(1) : result
}

export function getFileNameWithoutExtension(url) {
  if (!url) return ''
  const fileName = url.split('/').pop()
  const nameWithoutTimestamp = fileName.replace(/^\d+-/, '')
  return nameWithoutTimestamp.replace(/\.[^/.]+$/, '')
}

export function getFileNameWithExtension(url) {
  if (!url) return ''
  const fileName = url.split('/').pop()
  return fileName.replace(/^\d+-/, '')
}

export function getFileName(filePath) {
  if (!filePath) return ''
  return filePath.substring(filePath.lastIndexOf('/') + 1)
}

export function getFileType(filePath) {
  if (!filePath) return ''
  return toUpper(filePath.split('.').pop())
}

// String Formatting
export function truncate(input, length = 10) {
  if (input && input.length > length) {
    return input.substring(0, length) + '...'
  }
  return input
}

export function uppercase(str) {
  if (!str) return ''
  return str.toUpperCase()
}

export function lowercase(str) {
  if (!str) return ''
  return str.toLowerCase()
}

export function toUpper(str) {
  return (str || '').toUpperCase()
}

export function toLower(str) {
  return (str || '').toLowerCase()
}

export function capitalizeWords(str) {
  if (!str) return ''
  return str.replace(/\b\w/g, char => char.toUpperCase())
}

// Number Formatting
export function formatNumber(number, abbreviate = true) {
  if (number == undefined) return 0
  
  if (number >= 9999) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  } else if (number >= 1000) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return abbreviate ? number.toString() : number.toFixed(2)
}

export function formatDollars(amount) {
  return '$' + formatNumber(amount, false)
}

export function formatCents(amount) {
  return '$' + formatNumber(amount / 100, false)
}

// Comparison Functions
export function numberGreaterThan(num1, num2) {
  return !isNaN(num1) && !isNaN(num2) && parseFloat(num1) > parseFloat(num2)
}

export function numberLessThan(num1, num2) {
  return !isNaN(num1) && !isNaN(num2) && parseFloat(num1) < parseFloat(num2)
}

export function containsIgnoreCase(str1, str2) {
  if (!str1 || !str2) return false
  return (str1 || '').toString().toLowerCase().includes((str2 || '').toString().toLowerCase())
}

export function equalsIgnoreCase(str1, str2) {
  if (!str1 || !str2) return false
  return str1.toString().toLowerCase() === str2.toString().toLowerCase()
}

// Type Checking
export function isType(ann, type) {
  return {
    button: ann.type_of === 'button',
    link: ann.type_of === 'link',
    text: ann.type_of === 'text',
    image: ann.type_of === 'image',
    video: ann.type_of === 'video',
    audio: ann.type_of === 'audio',
    article: ann.type_of === 'article',
    cart: ann.type_of === 'cart',
    question: ann.type_of === 'question',
    hotspot: ann.type_of === 'hotspot',
  }
}

// Action & Type Conversions
export function annTypeToWord(type) {
  const { t } = useI18n({})

  switch (type) {
    case 'redirect': return t('common.redirect_link')
    case 'jump': return t('common.change_time')
    case 'switch': return t('common.switch_video')
    case 'back': return t('common.previous_video')
    case 'pause': return t('common.pause')
    case 'resourceTray': return t('common.open_magic_menu')
    case 'variable': return t('common.set_variable')
    case 'reset_viewer': return t('common.reset_viewer')
    case 'chatGPT': return t('common.ask_chatgpt')
    default: return t('common.none')
  }
}

export function needsClickValue(action) {
  return !['none', 'back', 'track', 'resourceTray'].includes(action)
}

export function clickActions() {
  const { t } = useI18n({})
  return [
    { label: t('common.continue'), value: 'track' },
    { label: t('common.open_link'), value: 'link' },
    { label: t('common.change_time'), value: 'jump' },
    { label: t('common.switch_video'), value: 'video' },
    { label: t('common.show_message'), value: 'message' },
    { label: t('common.switch_to_previous_video'), value: 'back' },
    { label: t('common.show_article'), value: 'article' },
    { label: t('common.show_image'), value: 'view' },
    { label: t('common.open_genie_ai'), value: 'genie' },
    { label: t('common.ask_chatgpt'), value: 'openai' },
    { label: t('common.play_video_clip'), value: 'clip' },
    { label: t('common.play_audio_clip'), value: 'audio' },
    { label: t('common.start_email'), value: 'email' },
    { label: t('common.start_call'), value: 'phone' },
    { label: t('common.open_link_modal'), value: 'modal' },
    { label: t('common.collect_response'), value: 'collect' },
    { label: t('common.download_file'), value: 'download' },
    { label: t('common.open_magic_menu'), value: 'resourceTray' },
    { label: t('common.show_card'), value: 'show_card' },
    { label: t('common.connector_get_request'), value: 'get' },
    { label: t('common.connector_post_request'), value: 'post' },
    { label: t('common.add_to_cart'), value: 'cart' },
    { label: t('common.none'), value: 'none' },
  ]
}

export function friendlyActionSummary(ann) {
  const { t } = useI18n({})
  
  switch (ann.click_action) {
    case 'link': return t('common.open_link') + ann.click_value
    case 'jump': return t('common.change_time') + formatSeconds(ann.click_value)
    case 'video': return t('common.switch_video') + annStore.findVideo(ann.click_value)?.title
    case 'message': return t('common.show_message') + ': ' + ann.click_value
    case 'article': return t('common.show_article') + ': ' + annStore.findArticle(ann.click_value)?.title
    case 'view': return t('common.show_image') + ': ' + annStore.findImage(ann.click_value)?.title
    case 'cart': return t('common.add_to_cart')
    case 'clip': return t('common.play_video_clip') + ': ' + annStore.findSource(ann.click_value)?.title
    case 'audio': return t('common.plays_audio_clip') + ': ' + annStore.findAudio(ann.click_value)?.title
    case 'email': return t('common.start_email')
    case 'phone': return t('common.start_call')
    case 'modal': return t('common.open_link_modal') + ': ' + ann.click_value
    case 'collect': return t('common.collect_response') + ': ' + ann.click_value
    case 'download': return t('common.download_file') + ': ' + annStore.findFile(ann.click_value)?.title
    case 'show_card': return t('common.show_card')
    case 'get': return t('common.connector_get_request')
    case 'post': return t('common.connector_post_request')
    case 'none': return t('common.none')
    case 'back': return t('common.goes_back')
    case 'genie': return t('common.open_genie_ai')
    case 'openai': return t('common.ask_chatgpt')
    case 'resourceTray': return t('common.open_magic_menu')
    default: return t('common.none')
  }
}

// Animation
export function animationToWord(animation) {
  const { t } = useI18n({})
  
  switch (animation) {
    case 'pulse_v1': return t('common.pulse')
    case 'gelatine_v1': return t('common.gelatine')
    case 'spin': return t('common.spin')
    case 'elastic-spin': return t('common.elastic_spin')
    case 'hithere_v1': return t('common.hithere')
    case 'grow_v1': return t('common.grow')
    case 'bounce_v1': return t('common.bounce')
    case 'flip_v1': return t('common.flip')
    case 'shake_v1': return t('common.shake')
    case 'swing_v1': return t('common.swing')
    case 'wobble_v1': return t('common.wobble')
    case 'flash_v1': return t('common.flash')
    case 'jumbotron_v1': return t('common.jumbotron')
  }
}

// Question Types
export function questionTypeToWord(type) {
  const { t } = useI18n({})
  const questionTypes = [
    { label: t('common.free_response'), value: 'free' },
    { label: t('common.multiple_choice'), value: 'multiple' },
    { label: t('common.choose_all_that_apply'), value: 'all' },
    { label: t('common.dropdown_select'), value: 'select' },
    { label: t('common.number_response'), value: 'number' },
    { label: t('common.date_response'), value: 'date' },
    { label: t('common.video_response'), value: 'video' },
    { label: t('common.voice_response'), value: 'voice' },
    { label: t('common.drawing_response'), value: 'draw' },
    { label: t('common.image_response'), value: 'image' },
    { label: t('common.rating_1_5'), value: 'rating' },
    { label: t('common.likert_scale'), value: 'likert' },
    { label: t('common.display_poll'), value: 'poll' }
  ]
  const questionType = questionTypes.find(q => q.value === type)
  return questionType ? questionType.label : t('common.none')
}

// Conditionals
export function conditionalSummary(ann, full = true) {
  const { t } = useI18n({})
  let str = ''
  
  if (ann.is_conditional) {
    if (full) {
      if (ann.conditional_show) str += t('common.show_if') + ' '
      if (ann.conditional_hide) str += t('common.hide_if') + ' '
    }
    
    str += capitalizeWords(ann.conditional_variable) + ' '
    str += ann.conditional_assertion + ' '
    str += ann.conditional_value + ' '

    if (ann.is_conditional_2) {
      str += ' '
      str += ann.conditional_operator == 0 ? t('common.and_capital') : t('common.or_capital')
      str += ' '
      str += capitalizeWords(ann.conditional_variable_2) + ' '
      str += ann.conditional_assertion_2 + ' '
      str += ann.conditional_value_2 + ' '
    }
  }
  return str
}
// Action Prompts
export function actionToPrompt(action) {
  const { t } = useI18n({})
  
  switch (action) {
    case 'link': return t('common.website_link')
    case 'jump': return t('common.select_time')
    case 'video': return t('common.select_video')
    case 'message': return t('common.enter_message')
    case 'article': return t('common.select_article')
    case 'view': return t('common.select_image')
    case 'cart': return t('common.select_product')
    case 'clip': return t('common.select_clip')
    case 'audio': return t('common.select_audio')
    case 'email': return t('common.enter_email')
    case 'phone': return t('common.enter_phone')
    case 'modal': return t('common.enter_link')
    case 'collect': return t('common.enter_prompt')
    case 'download': return t('common.select_file')
    case 'show_card': return t('common.show_card')
    case 'get': return t('common.get')
    case 'post': return t('common.post')
    case 'none': return t('common.none')
    case 'back': return t('common.back')
    case 'genie': return t('common.genie_ai')
    case 'openai': return t('common.enter_prompt')
    case 'resourceTray': return t('common.magic_menu')
    default: return t('common.none')
  }
}

// Country Handling
export function countryToEmoji(country) {
  const countryMap = {
    'United States': '🇺🇸',
    'Canada': '🇨🇦',
    'United Kingdom': '🇬🇧',
    'Australia': '🇦🇺',
    'Germany': '🇩🇪',
    'France': '🇫🇷',
    'Italy': '🇮🇹',
    'Japan': '🇯🇵',
    'China': '🇨🇳',
    'Brazil': '🇧🇷',
    'India': '🇮🇳',
    'Mexico': '🇲🇽',
    'Russia': '🇷🇺',
    'South Korea': '🇰🇷',
    'Turkey': '🇹🇷',
    'Spain': '🇪🇸',
    'Argentina': '🇦🇷',
    'Chile': '🇨🇱',
    'Colombia': '🇨🇴',
    'Peru': '🇵🇪',
    'Indonesia': '🇮🇩',
    'Pakistan': '🇵🇰',
    'Nigeria': '🇳🇬',
    'South Africa': '🇿🇦',
    'Egypt': '🇪🇬',
    'Kenya': '🇰🇪'
  }
  
  return countryMap[country] || ''
}