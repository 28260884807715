// services/videoService.js
export const VideoService = {
  async branchToVideo(token, { router, player, playback, video }) {
    console.log("⭐️ VideoService branchToVideo", token)
    console.log("🔎 VideoService branchToVideo", router, player, playback, video)
    if (!token) {
      console.log("🔴 VideoService branchToVideo: no token provided")
      return
    }
    player.pause()



    // Branch to URL, based on playback state
    const url = playback.started ? `/w/${token}` : `/videos/${token}`
    // Finish current playback tracking
    playback.finishTracking()

    // Set reference for potential "back" navigation
    playback.refer_video = video.video.token

    // Prepare player state
    player.branchTransition = video.video.design.switch_transition

    // Can't take screenshot of youtube videos, so we branch immediately
    let isYoutube = video.video.source.type_of === 'youtube'
    if (isYoutube) {
      player.branching = true
    } else {
      player.takeScreenshot().then(() => {
        console.log("🔄 VideoService branchToVideo screenshot taken")
        player.branching = true
      })
    }

    try {
      await video.show(token, { manage: video.video.manage })
      console.log("🔄 VideoService branchToVideo url:", url)
      // This is now handled in the video store
      // router.push(url)
    } catch (error) {
      console.log("🔴 VideoService branchToVideo error:", error)
    }
  },

  /**
   * Takes a high-resolution screenshot of a video element
   * @param {HTMLVideoElement} videoElement - The video element to capture
   * @param {Object} options - Screenshot options
   * @param {number} options.scale - Scale factor for resolution (default: 1)
   * @param {string} options.type - Image type (default: 'image/png')
   * @param {number} options.quality - Image quality for jpeg (0-1, default: 0.95)
   * @returns {Promise<string>} - Returns a promise that resolves with the screenshot data URL
   */
  takeVideoScreenshot(videoElement, options = {}) {
    console.log("⭐️ VideoService takeVideoScreenshot", videoElement, options)
    return new Promise((resolve, reject) => {
      // Validate input
      if (!(videoElement instanceof HTMLVideoElement)) {
        reject(new Error('First argument must be an HTMLVideoElement'));
        return;
      }

      // Default options
      const {
        scale = 1,
        type = 'image/png',
        quality = 0.8
      } = options;

      // Create canvas with scaled dimensions
      const canvas = document.createElement('canvas');
      const width = videoElement.videoWidth || videoElement.clientWidth;
      const height = videoElement.videoHeight || videoElement.clientHeight;

      canvas.width = width * scale;
      canvas.height = height * scale;

      try {
        const ctx = canvas.getContext('2d');

        // Enable image smoothing for better quality
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        // Check if video is ready
        if (videoElement.readyState < 2) {
          reject(new Error('Video must be loaded before taking screenshot'));
          return;
        }

        // Draw the video frame to canvas with scaling
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        // Convert to data URL
        const dataURL = canvas.toDataURL(type, quality);

        // Validate the screenshot was successful
        if (dataURL === 'data:,') {
          reject(new Error('Failed to capture video screenshot'));
          return;
        }

        resolve(dataURL);
      } catch (error) {
        // Handle potential security errors (e.g., cross-origin video)
        reject(new Error(`Failed to take screenshot: ${error.message}`));
      }
    });
  }

  // Other video-related operations...
}

// Later usage in ClickComposer:
// function doVideoBranch(token) {
//   VideoService.branchToVideo(token, {
//     router,
//     player: playerStore,
//     playback: playbackStore,
//     video: videoStore
//   })
// }

// // And in AnnStore:
// doShow(ann) {
//   if (ann.type_of === 'switch' && !alreadyDone) {
//     VideoService.branchToVideo(
//       ann.click_value.split("---")[1], 
//       {
//         router: this.router,
//         player: playerStore,
//         playback: playbackStore,
//         video: videoStore
//       }
//     )
//   }
// }