import { useRoute, useRouter } from "vue-router";

// Helper to get router instance when needed
function getRouter() {
  return useRouter();
}

// Helper to get route instance when needed
function getRoute() {
  return useRoute();
}

export function addParamToUrl(key, value) {
  const router = getRouter();
  const route = getRoute();
  
  router.replace({ 
    query: { 
      ...route.query,
      [key]: value 
    }
  });
}

export function getUrlParameter(name) {
  const url = window.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(url);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function setAppDocumentTitle(title) {
  document.title = title + ' - Mindstamp'
}

export function focus(id, delay = 10) {
  console.log("🔎 focus", id, document.getElementById(id));
  setTimeout(() => {
    document.getElementById(id)?.focus();
  }, delay);
}

export function focusAndSelect(id, delay = 10) {
  console.log("🔎 focusAndSelect", id, document.getElementById(id));
  focus(id, delay);
  setTimeout(() => {
    document.getElementById(id)?.select();
  }, delay);
}

export function highlightBox(id, delay = 10, length = 2000) {
  setTimeout(() => {
    const el = document.getElementById(id);
    if (el) {
      el.classList.add('focusHighlightBox');
      setTimeout(() => {
        el.classList.remove('focusHighlightBox');
      }, length);
    }
  }, delay);
}

export function focusAndHighlight(id, delay = 10, length = 2000, error = false) {
  console.log("🔎 focusAndHighlight", id, document.getElementById(id));
  focus(id, delay);
  setTimeout(() => {
    const el = document.getElementById(id);
    if (el) {
      el.classList.add(error ? 'focusErrorHighlight' : 'focusHighlight');
      setTimeout(() => {
        el.classList.remove(error ? 'focusErrorHighlight' : 'focusHighlight');
      }, length);
    }
  }, delay);
}

export function toggleAutocomplete(id, on, delay = 10) {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element) {
      element.autocomplete = on ? "on" : "off";
    }
  }, delay);
}

export function toggleAutocompleteByElement(element, on, delay = 10) {
  setTimeout(() => {
    element.autocomplete = on ? "on" : "off";
  }, delay);
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}