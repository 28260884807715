<template>  
  <div class="h-screen bg-black">
    <BlockLayer v-if="videoStore.video.blocked" />
    <Player v-else-if="!videoStore.loading && videoStore.video.source" :autoplay="autoplay" :time="time" :loop="loop" :isPlayback="true" class="h-full"/>
  </div>
</template>
<script setup>
import { VideoStore } from "@/front/stores/video_store.js";
import { PlaybackStore } from '@/front/stores/playback_store.js';
import { LeadCaptureStore } from '@/front/stores/lead_capture_store.js';
import { useRoute } from 'vue-router';
import { MessageComposer } from '@/front/composables/MessageComposer.js';
import Player from '@/front/components/player/Player.vue';
import BlockLayer from '@/front/components/BlockLayer.vue';

const videoStore = VideoStore();
const playbackStore = PlaybackStore();
const leadCaptureStore = LeadCaptureStore();
const { receiveMessage } = MessageComposer();
const route = useRoute();

const autoplay = route.query.autoplay == "1" || route.query.t;
const time = route.query.t;
const loop = route.query.loop == "1";
let paymentStore = null;

onMounted(async () => {
  window.addEventListener('message', receiveMessage);
  
  await videoStore.show(route.params.id, { 
    manage: false, 
    plink_id: route.query.plink_id 
  });

  if (videoStore.video.blocked) return;

  if (videoStore.video.capture_payment_point > -1) {
    const { PaymentStore } = await import('@/front/stores/payment_store.js');
    paymentStore = PaymentStore();
    paymentStore.setConfig(videoStore.video);
  }

  videoStore.video.manage = false;
  playbackStore.initialize(route, false);
  leadCaptureStore.setConfig(videoStore.video);

  if (videoStore.video.collect_geolocation) {
    navigator.geolocation.getCurrentPosition(
      pos => {
        playbackStore.latitude = pos.coords.latitude;
        playbackStore.longitude = pos.coords.longitude;
      },
      null,
      {maximumAge:60000, timeout:5000, enableHighAccuracy:true}
    );
  }
});
</script>