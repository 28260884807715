import { defineStore } from 'pinia'

export const UserStore = defineStore('user', {
  state: () => {
    return {
      // user: JSON.parse(localStorage.getItem("user")) || {},
      // organization: JSON.parse(localStorage.getItem("organization")) || {}
      user: {},
      organization: {},
      orgUsers: {},
      billing: {},
      stats: {},
      affiliate: null,
      present: localStorage.getItem("user") != null
    }
  },

  actions: {
    async fetch(path) {
      return this.Api.get('/users/me').then(response => {  
        this.setUser(response.data)
      })  
    },

    async show(id) {
      return this.Api.get(`/views/${id}`).then(response => {  
        
        this.view = response.data.view;        
      })  
    },

    async destroy(id) {      
      return this.Api.destroy(`/views/${id}`).then(response => {  
        this.errors = {};
      }).catch(error => {
        this.errors = error.response.data.errors;
      }) 
    },

    async update(id) {      
      return this.Api.put(`/users/me`, { user: this.user }).then(response => {  
        this.setUser(response.data)
        this.errors = {};
      }).catch(error => {
        this.errors = error.response.data.errors;
      }) 
    },

    async updateOrganization() {      
      return this.Api.put(`/organizations/${this.organization.id}`, this.organization ).then(response => {  
        console.log("Successfully updated organization", response.data)
        this.organization = response.data.organization
        this.errors = {};
      }).catch(error => {
        console.log("Error when updating organization")
        console.log(error)
        this.errors = error.response.data.errors;
      }) 
    },
    async addFont(font) {
      this.organization.fonts.push(font)
      this.updateOrganization().then(() => {
        console.log("🌜 Font added", font)
      })
    },

    async fetchOrgUsers() {
      return this.Api.get(`/organizations/users`).then(response => {  
        console.log("🤩🤩🤩 Fetching organization users", response.data)
        this.orgUsers = response.data
      })
    },

    setUser(data) {
      if (data.user) {
        this.user = data.user
        this.organization = data.organization
        this.orgUsers = data.members
        this.user.is_sysadmin = data.is_sysadmin
        localStorage.setItem("user", JSON.stringify(this.user))
        localStorage.setItem("organization", JSON.stringify(this.organization))
        localStorage.setItem("orgUsers", JSON.stringify(this.orgUsers))
        this.present = true
      } else {
        this.present = false
        // console.log("❌ No user")
      }
    },

    async checkUrlPath(urlPath) {
      return this.Api.get(`/organizations/check_url_path?url_path=${urlPath}`).then(response => {  
        console.log("Valid: ", response.data)
        return response.data.valid
      })  
    },

    async createInvite(email) {
      return this.Api.post(`/invites`, { email: email }).then(response => {  
        console.log("Invite created", response.data)
      })
    },

    async destroyInvite(id) {
      return this.Api.destroy(`/invites/${id}`).then(response => {  
        console.log("Invite destroyed", response.data)
      })
    },

    async remindInvite(id) {
      return this.Api.post(`/invites/${id}/remind`).then(response => {  
        console.log("Invite reminded", response.data)
      })
    },

    async fetchOrgQuotas() {
      return this.Api.get(`/organizations/quotas`).then(response => {  
        console.log("Quotas fetched", response.data)
        this.orgQuotas = response.data
      })
    },

    async fetchBilling() {
      return this.fetch().then(() => {  
        return this.Api.get(`/users/billing`).then(response => {  
          console.log("Billing fetched", response.data)
          this.billing = response.data
        })
      })
    },

    async getCurrentInvoice() {
      return this.Api.get(`/users/current_invoice`).then(response => {  
        console.log("Current invoice fetched", response.data)
        return response.data
      })
    },

    async keepSubscription() {
      return this.Api.post(`/users/keep_subscription`).then(response => {  
        console.log("Subscription kept", response.data)
      })
    },

    async cancelSubscription(reason, answer) {
      return this.Api.post(`/users/cancel_subscription`, { reason: reason, answer: answer }).then(response => {  
        console.log("Subscription canceled", response.data)
      })
    },

    async fetchStats() {
      return this.Api.get(`/users/stats`).then(response => {  
        console.log("Stats fetched", response.data)
        this.stats = response.data
      })
    },


    async pauseSubscription() {
      return this.Api.post(`/users/pause_subscription`).then(response => {  
        console.log("Subscription paused", response.data)
      })
    },

    async resumeSubscription() {
      return this.Api.post(`/users/resume_subscription`).then(response => {  
        console.log("Subscription resumed", response.data)
      })
    },

    async getStripePortalSession() {
      return this.Api.get(`/users/stripe_portal_session`).then(response => {  
        console.log("Stripe portal session fetched", response.data)
        return response.data
      })
    },

    async getCheckoutSession(plan, annual) {
      let rewardful_id = window.Rewardful && window.Rewardful.referral || ('checkout_'+(new Date).getTime());
      return this.Api.get(`/users/stripe_checkout_session?plan=${plan}&annual=${annual}&rewardful_id=${rewardful_id}`).then(response => {  
        console.log("Checkout session fetched", response.data)
        return response.data
      })
    },

    async doAffiliateSignup() {
      return this.Api.post(`/users/affiliates`).then(response => {  
        console.log("Affiliate signup", response.data)
        return response
      })
    },

    async getAffiliate() {
      return this.Api.get(`/users/affiliates/me`).then(response => {  
        console.log("Affiliate fetched", response.data)
        this.affiliate = response.data.affiliate
      })
    },
  },
  // TODO permissions
  getters: {
    canAccess: (state) => {
      return {
        scorm: true
      }
    }
  }
})