export const StyleService = {
  loadedFonts: new Set(),

  async loadAndApplyFont(fontName, fontLink) {
    console.log("🌜 StyleService loadAndApplyFont", fontName, fontLink)
    await this.loadFont(fontName, fontLink)
    setTimeout(() => {
      const playerContainer = document.getElementById("player-container")
      if (playerContainer) {
        playerContainer.style.fontFamily = fontName
      }
    }, 100)
  },

  async loadFont(fontName, fontLink) {
    console.log("🌜 StyleService loadFont", fontName, fontLink)
    if (!fontName || !fontLink) return

    try {
      // Check if font is already loaded
      if (this.loadedFonts.has(fontName)) {
        return
      }

      // Check if this is a CSS stylesheet (Google Fonts style)
      if (fontLink.includes('css2') || fontLink.includes('css')) {
        await this.loadFontFromStylesheet(fontName, fontLink)
      } else {
        // Direct font file
        await this.loadFontFromFile(fontName, fontLink)
      }

      this.loadedFonts.add(fontName)
      console.log("🌜 StyleService loadFont", fontName, "loaded")
    } catch (error) {
      console.error('Error loading font:', error)
    }
  },

  async loadFontFromFile(fontName, fontLink) {
    const font = new FontFace(fontName, `url(${fontLink})`)
    await font.load()
    document.fonts.add(font)
  },

  async loadFontFromStylesheet(fontName, stylesheetUrl) {
    // Fetch the stylesheet content
    const response = await fetch(stylesheetUrl)
    const css = await response.text()

    // Parse the CSS to find all @font-face declarations
    const fontFaceRules = this.parseFontFaceRules(css)

    // Load each font variation
    const loadPromises = fontFaceRules.map(async rule => {
      try {
        // Create FontFace instance with the parsed properties
        const font = new FontFace(
          fontName,
          rule.src,
          {
            weight: rule.weight,
            style: rule.style,
            unicodeRange: rule.unicodeRange,
            display: rule.display
          }
        )

        await font.load()
        document.fonts.add(font)
      } catch (error) {
        console.error('Error loading font variation:', error)
      }
    })

    await Promise.all(loadPromises)
  },

  parseFontFaceRules(css) {
    const rules = []
    const fontFaceRegex = /@font-face\s*{([^}]*)}/g
    const propertyRegex = /([a-zA-Z-]+)\s*:\s*([^;]+);/g

    let match
    while ((match = fontFaceRegex.exec(css)) !== null) {
      const ruleContent = match[1]
      const rule = {}
      let propertyMatch

      while ((propertyMatch = propertyRegex.exec(ruleContent)) !== null) {
        const [, property, value] = propertyMatch
        
        switch (property) {
          case 'src':
            // Extract URL and format from src
            const urlMatch = value.match(/url\(([^)]+)\)\s*format\('?([^)']+)'?\)/)
            if (urlMatch) {
              rule.src = `url(${urlMatch[1]})`
              rule.format = urlMatch[2]
            }
            break
          case 'font-weight':
            rule.weight = value
            break
          case 'font-style':
            rule.style = value
            break
          case 'unicode-range':
            rule.unicodeRange = value
            break
          case 'font-display':
            rule.display = value
            break
        }
      }

      if (rule.src) {
        rules.push(rule)
      }
    }

    return rules
  },

  clearLoadedFonts() {
    this.loadedFonts.clear()
  },

  isLoaded(fontName) {
    return this.loadedFonts.has(fontName)
  }
}