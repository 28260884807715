<script lang="ts" setup>
// Import styles.
import 'vidstack/player/styles/base.css';
// Register elements.
import 'vidstack/player';
import 'vidstack/player/ui';
import 'vidstack/icons';


import { isHLSProvider, type MediaCanPlayEvent, type MediaProviderChangeEvent } from 'vidstack';
import type { MediaPlayerElement } from 'vidstack/elements';
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import { PlayerStore } from '@/front/stores/player_store.js';
const playerStore = PlayerStore();

import VideoLayout from '@/front/components/player/components/layouts/VideoLayout.vue';

const props = defineProps({
  controls: {
    type: Boolean,
    default: true,
  },
  loop: {
    type: Boolean,
    default: false,
  },
})


const domVideo = ref<HTMLVideoElement>();
import { VideoStore } from '@/front/stores/video_store.js';
const videoStore = VideoStore();
import { UploadStore } from '@/front/stores/upload_store.js';
const uploadStore = UploadStore();

const $loopPlayer = ref<MediaPlayerElement>();
const $src = ref('');


// Method to extract the video source from the video object
function getVideoSource(video) {
  return uploadStore.uploadFile && uploadStore.uploadSource
    ? uploadStore.uploadSource
    : video.source.hls || video.source.mp4 || video.source.s3 || '';
}

const emit = defineEmits(['ended'])

onMounted(() => {
  // const { currentSrc, controlsHidden, currentTime, duration, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, ended, error, height, muted, paused, playbackRate, seeking, started, width } = $player.value?.state
  $src.value = getVideoSource(videoStore.video)

  // $player.value?.subscribe(({ currentSrc, controlsHidden, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, duration, ended, error, height, muted, paused, playbackRate, seeking, started, width }) => {
  //   clipStore.setPlayerState({ currentSrc, controlsHidden, fullscreen, playing, qualities, quality, seekableEnd, seekableStart, textTrack, volume, waiting, controlsVisible, duration, ended, error, height, muted, paused, playbackRate, seeking, started, width })
  // });
});

function onProviderChange(event: MediaProviderChangeEvent) {
  const provider = event.detail;
  // We can configure provider's here.
  if (isHLSProvider(provider)) {
    provider.config = {};
  }
}

// function onEnded() {
//   console.log("ClipPlayer onEnded emitting ended")
//   emit('ended')
// }

  //

// We can listen for the `can-play` event to be notified when the player is ready.
function onCanPlay(event: MediaCanPlayEvent) {
  setTimeout(() => {
    domVideo.value = document.querySelector('#loop-player video')
    console.log("LoopingPlayer onCanPlay", domVideo.value)
    domVideo.value.muted = true
    domVideo.value.volume = 0
    domVideo.value.play()
  }, 10)
  // ...
}

// onBeforeUnmount(() => {
//   clipStore.clear()
// })

function onTimeUpdate(event: MediaTimeUpdateEvent) {
  const t = Math.floor(event.detail.currentTime)
  if (t > 5) {
    domVideo.value.currentTime = 0
  }
}

const getCrossOrigin = computed(() => {
  // console.log("🌜 Player getCrossOrigin", $src.value)
  if ($src.value.includes("cloudfront")) {
    return false
  } else if ($src.value.includes("panopto")) {
    return 'anonymous'
  } else {
    return 'anonymous'
  }
});

const onLoopClick = () => {
  console.log("LoopingPlayer onClipClick")
  playerStore.togglePlayer()
  return
  // emit('click')
}

</script>

<template>
  <media-player
    class="w-full aspect-video bg-black text-white font-sans overflow-hidden ring-media-focus data-[focus]:ring-4"
    title="Sprite Fight"
    :src="$src"
    :crossOrigin="getCrossOrigin"
    playsInline
    loop
    :muted="true"
    @click.stop="onLoopClick"
    @ended="onEnded"
    @provider-change="onProviderChange"
    @time-update="onTimeUpdate"
    @can-play="onCanPlay"
    ref="$loopPlayer"
    id="loop-player"
  >
    <media-provider id="media-provider">
    </media-provider>
  </media-player>
</template>