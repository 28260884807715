<script setup>
import { AnnStore } from '@/front/stores/ann_store.js';
const annStore = AnnStore();

import { PlayerStore } from '@/front/stores/player_store.js';
const playerStore = PlayerStore();

import { DesignStore } from '@/front/stores/design_store.js';
const designStore = DesignStore();
import ListButton from '@/front/components/annotations/ListButton.vue';
import ListHotspot from '@/front/components/annotations/ListHotspot.vue';
import ListImage from '@/front/components/annotations/ListImage.vue';
import ListText from '@/front/components/annotations/ListText.vue';


const defaultWidth = 12

const getStyle = (ann, hoverAnn) => {
  if (!playerStore.playerState.duration) return {}
  const p = ann.exact_time / playerStore.playerState.duration
  const padding = 15.5
  const defaultWidth = 12
  const borderWidth = 2
  const l = p * (playerStore.playerState.width - 2*padding) + padding - defaultWidth/2 + borderWidth
  const w = (ann.exact_finish - ann.exact_time) / playerStore.playerState.duration * 100
  // TODO width for playback mod
  return {
    left: `${l}px`,
    backgroundColor: designStore.design.color,
    opacity: false && hoverAnn && hoverAnn.id === ann.id ? 0.3 : 1,
    width: false && hoverAnn && hoverAnn.id === ann.id? `${w}%` : `${defaultWidth}px`,
    minWidth: `${defaultWidth}px`
  }
}

const normalAnns = computed(() => {
  return annStore.anns.filter(ann => !ann.tray_type).filter(ann => !ann.hidden)
})

const clickMarker = (ann) => {
  console.log("clickMarker", ann)
  console.log(ann.exact_time, playerStore.playerState.duration, ann.exact_time / playerStore.playerState.duration * 100)
  playerStore.seek(ann.exact_time - 0.1)
}

const getTooltipsStyle = (ann, markerAnn) => {
  const style = {
    display: markerAnn && markerAnn.id === ann.id ? 'flex' : 'none',
    position: 'absolute',
    top: '-120px',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '100px',
  }
  return style
}

</script>

<template>
  <!-- <div v-if="annStore" id="marker-bar" class="absolute w-full h-96 flex items-center" b> -->
    <div 
      v-for="ann in normalAnns.filter(ann => !ann.hidden && ann.visible)" 
      @click.stop.prevent="clickMarker(ann)"
      :key="ann.id" 
      @mouseenter.stop="annStore.startMarkerHover(ann)"
      @mouseleave.stop="annStore.endMarkerHover()" 
      :style="getStyle(ann, annStore.hoverAnn)"
      class="h-6 absolute rounded-lg border-2 border-white -ml-1 z-10 cursor-pointer" 
      :id="`marker-${ann.id}`"
      >
      <div :style="getTooltipsStyle(ann, annStore.markerAnn)" class="absolute top-0 left-0 items-center justify-center bg-white rounded-lg shadow-md w-auto min-w-[200px] text-xs px-4"> 
        <ListButton v-if="ann.type_of == 'button'" :ann="ann" />
        <ListHotspot v-else-if="ann.type_of == 'hotspot'" :ann="ann" />
        <ListImage v-else-if="ann.type_of == 'image'" :ann="ann" />
        <ListText v-else-if="ann.type_of == 'text'" :ann="ann" />
        <div v-else> {{ ann.type_of }}</div>
      </div>
    </div>
  <!-- </div> -->
</template>
