<script setup>

const props = defineProps({
  ann:{
    type: Object,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

import { VideoStore } from '@/front/stores/video_store.js';
import { PlayerStore } from '@/front/stores/player_store.js';
import { AnnStore } from '@/front/stores/ann_store.js';
import { ClickStore } from '@/front/stores/click_store.js';
const videoStore = VideoStore();
const playerStore = PlayerStore();
const annStore = AnnStore();
const router = useRouter();
const clickStore = ClickStore();



import { PositionCalculator } from '@/front/composables/PositionCalculator.js';
const { calculatePosition, getWidthRatio } = PositionCalculator()

const customStyle = props.ann.customStyle && JSON.parse(props.ann.customStyle) || {}
const styling = computed(() => {
  const baseDesign = videoStore.video.design || {}
  const widthRatio = getWidthRatio(props.ann)

  // TODO make sure this doesnt break in diff context
  const dimensions = calculatePosition(props.ann)
  const br = (widthRatio * (props.ann.border_radius) / 10) + 'em'


  // console.log("Border", br)
  let bgColor = props.ann.background_color || customStyle.background_color || baseDesign.button_background_color
  let textColor = props.ann.text_color || customStyle.color || baseDesign.button_text_color
  let opacity = props.disabled ? 0.5 : 1
  let cursor = props.disabled ? 'not-allowed' : 'pointer'
  
  if (props.ann.post_click_state == "color" && props.ann.clicked) {
    bgColor = props.ann.post_click_color
    textColor = props.ann.post_click_text_color
  }

  console.log("🔎 LayerButton styling", bgColor)
  return {
    'width': `${dimensions.width}px`,
    'height': `${dimensions.height}px`,
    'left': `${dimensions.left}px`,
    'top': `${dimensions.top}px`,
    'minHeight': `${dimensions.minHeight}px`,
    'fontSize': `${dimensions.fontSize}px`,
    'color': textColor, 
    'background': bgColor, 
    'borderColor': props.ann.border_color || customStyle.borderColor || baseDesign.button_border_color || 'red',
    'borderRadius': br,
    'padding': 0,
    'opacity': opacity,
    'cursor': cursor,
  }
})
</script>

<template>
  <button 
    class="button shadow-md border-2 w-full h-full transition-all duration-300" 
    :style="styling" 
    :class="annStore.isEditing ?  'animate-none' : `${ann.animation} hover:scale-105 z-10`"
  > 
    <span v-html="annStore.isEditing && annStore.editAnn.id == ann.id ? annStore.editAnn.content : ann.transformed_content || ann.content"></span>
  </button>

</template>